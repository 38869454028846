import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { TextField, Typography, Button, Container, Paper } from '@mui/material';
import '../assets/css/style.css';
import API_BASE_URL from '../config'; // Replace with your API base URL
import bg from '../assets/images/bg.jpeg'; // Adjust the path as necessary

// Styled components
const PageContainer = styled(Container)({
  display: 'flex',
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
  justifyContent: 'flex-end', // Align to the right side
  alignItems: 'center',
  paddingRight: '50px', // Add padding to move it slightly from the right edge
});

const LoginBox = styled(Paper)({
  padding: '30px',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  width: '100%',
});

const Logo = styled(Typography)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#7c3aed',
  marginBottom: '20px',
});

const ResponsiveTextField = styled(TextField)({
  marginBottom: '16px',
  width: '100%',
});

const LoginButton = styled(Button)({
  width: '100%',
  marginTop: '16px',
  backgroundColor: '#7c3aed',
  '&:hover': {
    backgroundColor: '#6d28d9',
  },
});

const ErrorMessage = styled(Typography)({
  color: 'red',
  marginTop: '10px',
});

const Guest = () => {
  const [input, setInput] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(90);
  const [isOTPSent, setIsOTPSent] = useState(false);

  useEffect(() => {
    if (isOTPSent && timer > 0) {
      const countdown = setInterval(() => setTimer((prevTimer) => prevTimer - 1), 1000);
      return () => clearInterval(countdown);
    }
  }, [isOTPSent, timer]);

  const validateInput = (input) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;
    return emailPattern.test(input) || phonePattern.test(input);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateInput(input)) {
      setMessage('Please enter a valid email address or a 10-digit mobile number.');
      return;
    }

    if (!isOTPSent) {
      try {
        const response = await fetch(`${API_BASE_URL}/guest`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ input }),
        });
        const data = await response.json();

        if (data.message === 'Create Account First') {
          setMessage('Please create an account first.');
        } else {
          setMessage(data.message);
          setIsOTPSent(true);
          setTimer(90);
        }
      } catch (error) {
        setMessage('Error sending OTP');
      }
    } else {
      verifyOTP(e);
    }
  };

  const handleResendOTP = () => {
    setIsOTPSent(false);
    setTimer(90);
    handleLogin(new Event('submit'));
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    if (otp.length !== 4) {
      setMessage('Please enter a valid 4-digit OTP.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/guest/verify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input, otp }),
      });
      const data = await response.json();

      if (data.redirectTo) {
        localStorage.setItem('isLoggedIn', 'true');
        window.location.href = data.redirectTo;
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      setMessage('Error verifying OTP');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Allow only 10 digits for phone number input
    if (/^\d*$/.test(value) && value.length <= 10) {
      setInput(value);
    } else if (!/^\d*$/.test(value)) {
      // If the input is not a number (likely an email), update as is
      setInput(value);
    }
  };

  return (
    <PageContainer maxWidth={false}  style={{ minHeight: '100vh', backgroundImage: `url(${bg})`,   backgroundPositionY: '-75px', backgroundPositionX: '-286px', }}>
      <LoginBox elevation={3} style={{ marginRight: '18%'}}>
        <Logo>GOLD PETALS (Guest Login)</Logo>
        <Typography variant="h5" gutterBottom className="mb-3">
          Welcome to Gold Petals! 👋🏻 
        </Typography>

        <form onSubmit={handleLogin} method="POST">
          <ResponsiveTextField
            label="Mobile Number or Email ID"
            variant="outlined"
            type="text"
            value={input}
            onChange={handleInputChange}
            required
          />
          {isOTPSent && (
            <>
              <ResponsiveTextField
                label="Enter OTP"
                variant="outlined"
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              <Typography variant="body2" color="textSecondary">
                {timer > 0
                  ? `Resend OTP in ${timer}s`
                  : <Typography component="span" color="primary" onClick={handleResendOTP}>Resend OTP</Typography>}
              </Typography>
            </>
          )}
          {message && <ErrorMessage>{message}</ErrorMessage>}
          <LoginButton variant="contained" type="submit">
            {isOTPSent ? 'Verify OTP' : 'Get OTP'}
          </LoginButton>
        </form>

        <div style={{ textAlign: 'center', marginTop: '24px' }}>
          <Typography variant="body2" gutterBottom>
            New on our platform? <Typography component="span" color="primary"><a href="/goldpetals/signup" style={{ textDecoration: 'none' }}> Create an account</a></Typography>
          </Typography>
          <Typography variant="body2" gutterBottom>
           Already have an Account? <Typography component="span" color="primary"><a href="/goldpetals/login" style={{ textDecoration: 'none' }}>Login</a></Typography>
          </Typography>
        </div>
      </LoginBox>
    </PageContainer>
  );
};

export default Guest;
