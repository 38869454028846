import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';

const SettingButton = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/settings'); // Redirect to the /home route
  };

  // Destructure match from props so it doesn't get passed to DOM
  const StyledListItemButton = styled(({ match, ...other }) => (
    <ListItemButton {...other} />
  ))(({ theme, match }) => ({
    backgroundColor: match ? theme.palette.primary.light : 'transparent',
    '&:hover': {
      backgroundColor: match ? theme.palette.primary.main : theme.palette.action.hover,
    },
  }));

  const isHome = location.pathname; // This can be dynamic based on your use case

  return (
    <StyledListItemButton onClick={handleHomeClick} match={isHome === '/settings'}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </StyledListItemButton>
  );
};

export default SettingButton;
