import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import axios from "axios";
import { useCenteredTree } from "../config"; // Custom hook for centering the tree
import API_BASE_URL from '../config';
import { BASE_URL} from '../config';
import { ReactComponent as MyIconF } from '../m.svg';
import { ReactComponent as MyIconM } from '../f.svg';

const containerStyles = {
  width: "100vw",
  height: "100vh",
};

// Recursive function to sort the tree by position
const sortTreeByPosition = (node) => {
  if (node.children && node.children.length > 0) {
    node.children.sort((a, b) => a.attributes.position - b.attributes.position);
    node.children.forEach(sortTreeByPosition);
  }
};

// Function to fetch family data
const fetchFamilyTreeData = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/id`);
    const userId = response.data.userId;

    const uResponse = await axios.get(`${API_BASE_URL}/relationship/${userId}`);
    const familyTreeData = uResponse.data; // Assuming API returns family data in the required format

    // Sort the family tree by position
    sortTreeByPosition(familyTreeData);

    return familyTreeData;
  } catch (error) {
    console.error("Error fetching family data:", error);
    return null;
  }
};

const renderRectSvgNode = ({ nodeDatum, toggleNode, showSpouse, setShowSpouse }) => {
  const handleToggleSpouse = () => {
    setShowSpouse(!showSpouse);
  };

  const handleClick = () => {
    // handleToggleSpouse();
  };

  // // Function to draw the curved path (Bezier curve)
  // const drawCurvedLine = (startX, startY, endX, endY, direction) => {
  //   const controlX = direction === "left" ? (startX + endX) / 2 - 50 : (startX + endX) / 2 + 50;
  //   const controlY = (startY + endY) / 2;
  //   return `M ${startX},${startY} Q ${controlX},${controlY} ${endX},${endY}`;
  // };

  return (
    <g onClick={handleClick}>
      {/* Background rectangle */}
      {/* <rect
        width="95"
        height="105"
        fill={nodeDatum.attributes.relationship === "Self"
          ? "lightblue"
          : 'white'}
        stroke="black"
        strokeWidth=".1"
        rx="100"
        ry="10"
        x={-50}
        y={-60}
        style={{ cursor: "pointer" }}
      /> */}

      {/* Show image if exists, else show gender-based icon */}
      {console.log(nodeDatum.attributes)}
      {nodeDatum.attributes.imageUrl ? (
        <image
          href={`${BASE_URL}${nodeDatum.attributes.imageUrl}`}
          x={-42}
          y={-55}
          width="80"
          height="80"
          clipPath="circle(50%)"
        />
      ) : (
        nodeDatum.attributes.gender === 'Female' ? (
          <MyIconF x={-47} y={-60} width="80" height="80" style={{
            borderRadius: '50%',
            overflow: 'hidden',
          }} />
        ) : (
          <MyIconM x={-47} y={-60} width="80" height="80" style={{
            borderRadius: '50%',
            overflow: 'hidden',
          }} />
        )
      )}

      {/* Name */}
      <g>
        {/* Background rectangle */}
        <rect
          x="-25"
          y="25"
          width={nodeDatum.name.length * 8}  // Approximate width based on name length
          height="20"
          fill="white"
        />

        {/* Text on top of the background */}
        <text
          fill="black"
          x="-20"
          y="37"
          strokeWidth="1"
          fontSize="12"
          className="roboto-thin"
        >
          {nodeDatum.name}
        </text>
      </g>


      {/* Father */}
      {nodeDatum.father && (
        <g>
          <line x1="0" y1="-140" x2="0" y2="-62" stroke="black" strokeWidth="1" />
          {/* <rect
            width="95"
            height="105"
            fill="white"
            stroke="black"
            strokeWidth=".1"
            rx="100"
            ry="10"
            x={-110}
            y={-210}
            style={{ cursor: "pointer" }}
          /> */}
          {nodeDatum.father.attributes.imageUrl ? (
            <image
            href={`${BASE_URL}${nodeDatum.father.attributes.imageUrl}`}
              x={-106}
              y={-212}
              width="80"
              height="80"
              clipPath="circle(50%)"
            />
          ) : nodeDatum.attributes.gender === 'Female' ? (
            <MyIconF x={-100} y={-212} width="80" height="80" style={{
              borderRadius: '50%',
              overflow: 'hidden',
            }} />
          ) : (
            <MyIconM x={-100} y={-212} width="80" height="80" style={{
              borderRadius: '50%',
              overflow: 'hidden',
            }} />
          )}
          
          <g>
        {/* Background rectangle */}
        <rect
         x="-77" y="-127"
          width={nodeDatum.father.name.length * 8}  // Approximate width based on name length
          height="20"
          fill="white"
        />

        {/* Text on top of the background */}
        <text
          fill="black"
         x="-75" y="-115"
          strokeWidth="1"
          fontSize="12"
          className="roboto-thin"
        >
          {nodeDatum.father.name}
        </text>
      </g>
        </g>
      )}

      {/* Mother */}
      {nodeDatum.mother && (
        <g>
          <line x1="90" y1="-140" x2="-36" y2="-140" stroke="black" strokeWidth="1" />
          {/* <rect
            width="95"
            height="105"
            fill="white"
            stroke="black"
            strokeWidth=".1"
            rx="100"
            ry="10"
            x={16}
            y={-210}
            style={{ cursor: "pointer" }}
          /> */}
          {nodeDatum.mother.attributes.imageUrl ? (
            <image
              href={`${BASE_URL}${nodeDatum.mother.attributes.imageUrl}`}
              x={20}
              y={-212}
              width="80"
              height="80"
              clipPath="circle(50%)"
            />
          ) : nodeDatum.attributes.gender === 'Male' ? (
            <MyIconF x={25} y={-212} width="80" height="80" style={{
              borderRadius: '50%',
              overflow: 'hidden',
            }} />
          ) : (
            <MyIconM x={25} y={-212} width="80" height="80" style={{
              borderRadius: '50%',
              overflow: 'hidden',
            }} />
          )}
         
          <g>
        {/* Background rectangle */}
        <rect
         x="45" y="-127"
          width={nodeDatum.mother.name.length * 8}  // Approximate width based on name length
          height="20"
          fill="white"
        />

        {/* Text on top of the background */}
        <text
          fill="black"
         x="47" y="-115"
          strokeWidth="1"
          fontSize="12"
          className="roboto-thin"
        >
          {nodeDatum.mother.name}
        </text>
      </g>
        </g>
      )}

      {/* Show spouse for level 1 (root node or first level) */}
      {nodeDatum.attributes.level === 1 && nodeDatum.spouse && (
        <g onClick={handleToggleSpouse}>
          <line x1="35" y1="0" x2="120" y2="0" stroke="black" strokeWidth="1" />
          {/* <rect
            width="95"
            height="105"
            fill="white"
            stroke="black"
            strokeWidth=".1"
            rx="100"
            ry="10"
            x={65}
            y={-60}
            style={{ cursor: "pointer" }}
          /> */}
          {nodeDatum.spouse.attributes.imageUrl ? (
            <image
              href={`${BASE_URL}${nodeDatum.spouse.attributes.imageUrl}`}
              x={72}
              y={-63}
              width="80"
              height="80"
              clipPath="circle(50%)"
            />
          ) : nodeDatum.spouse.attributes.gender === 'Male' ? (
            <MyIconF x={72} y={-63} width="80" height="80" style={{
              borderRadius: '50%',
              overflow: 'hidden',
            }} />
          ) : (
            <MyIconM x={72} y={-63} width="80" height="80" style={{
              borderRadius: '50%',
              overflow: 'hidden',
            }} />
          )}
          
          <g>
        {/* Background rectangle */}
        <rect
         x="95" y="22"
          width={nodeDatum.spouse.name.length * 8}  // Approximate width based on name length
          height="20"
          fill="white"
        />

        {/* Text on top of the background */}
        <text
          fill="black"
        x="97" y="33"
          strokeWidth="1"
          fontSize="12"
          className="roboto-thin"
        >
          {nodeDatum.spouse.name}
        </text>
      </g>
          {/* Display siblings arranged by their position relative to the root */}
          {nodeDatum.siblings && nodeDatum.siblings.length > 0 && (
            <g>
              {/* Filter and sort siblings into left and right based on the root's position */}
              {nodeDatum.siblings
                .sort((a, b) => a.attributes.position - b.attributes.position)
                .map((sibling, index) => {
                  const siblingPosition = sibling.attributes.position;
                  const rootPosition = nodeDatum.attributes.position;
                  const siblingOnRight = siblingPosition > rootPosition; // Sibling should appear on the right if its position is less than root's position
                  const siblingOnLeft = siblingPosition < rootPosition; // Sibling should appear on the left if its position is greater than root's position

                  const siblingSpacing = 120; // Increase this value to add more space between siblings

                  return (
                    <g key={index} onClick={handleClick}>
                      {/* Siblings on the right */}
                      {siblingOnRight && (
                        <g>
                          <path
                            d={`
        M 36,0
        C 50,-140 
          ${200 + index * siblingSpacing - (160 + index * siblingSpacing - 200) / 2},-120
          ${160 + index * siblingSpacing},-30
      `}
                            stroke="grey"
                            strokeWidth="1"
                            fill="transparent"
                          />
                          {/* <rect
                            width="95"
                            height="105"
                            fill="white"
                            stroke="black"
                            strokeWidth=".1"
                            rx="100"
                            ry="10"
                            x={160 + index * siblingSpacing}
                            y={-60}
                            style={{ cursor: "pointer" }}
                          /> */}
                          {sibling.attributes.imageUrl ? (
                            <image
                              href={sibling.attributes.imageUrl}
                              x={165 + index * siblingSpacing}
                              y={-65}
                              width="80"
                              height="80"
                              clipPath="circle(50%)"
                            />
                          ) : sibling.attributes.gender === 'Female' ? (
                            <MyIconF
                              x={160 + index * siblingSpacing}
                              y={-60}
                              width="90"
                              height="90" style={{
                                borderRadius: '50%',
                                overflow: 'hidden',
                              }}
                            />
                          ) : (
                            <MyIconM
                              x={160 + index * siblingSpacing}
                              y={-60}
                              width="80"
                              height="80" style={{
                                borderRadius: '50%',
                                overflow: 'hidden',
                              }}
                            />
                          )}
                          <text
                            fill="black"
                            x={188 + index * siblingSpacing}
                            y="34"
                            strokeWidth="1"
                            fontSize="12"
                            className="roboto-thin"
                          >
                            {sibling.name}
                          </text>
                          
                        </g>
                      )}

                      {/* Siblings on the left */}
                      {siblingOnLeft && (
                        <g>
                          <path
                            d={`
            M -42,-10
            C -50,-120 
              -${200 + index * siblingSpacing - (160 + index * siblingSpacing - 244) / 2},-100
              -${160 + index * siblingSpacing},-40
          `}
                            stroke="grey"
                            strokeWidth="1"
                            fill="transparent"
                          />
                          {/* <rect
                            width="95"
                            height="105"
                            fill="white"
                            stroke="black"
                            strokeWidth=".1"
                            rx="100"
                            ry="10"
                            x={-190 - index * siblingSpacing}
                            y={-60}
                            style={{ cursor: "pointer" }}
                          /> */}
                          {sibling.attributes.imageUrl ? (
                            <image
                            href={`${BASE_URL}${sibling.attributes.imageUrl}`}
                              x={-195 - index * siblingSpacing}
                              y={-65}
                              width="90"
                              height="90"
                              clipPath="circle(50%)"
                            />
                          ) : sibling.attributes.gender === 'Female' ? (
                            <MyIconF
                              x={-190 - index * siblingSpacing}
                              y={-60}
                              width="80"
                              height="80" style={{
                                borderRadius: '50%',
                                overflow: 'hidden',
                              }}
                            />
                          ) : (
                            <MyIconM
                              x={-190 - index * siblingSpacing}
                              y={-60}
                              width="80"
                              height="80" style={{
                                borderRadius: '50%',
                                overflow: 'hidden',
                              }}
                            />
                          )}
                          <text
                            fill="black"
                            x={-168 - index * siblingSpacing}
                            y="34"
                            strokeWidth="1"
                            fontSize="12"
                            className="roboto-thin"
                          >
                            {sibling.name}
                          </text>
                        </g>
                      )}
                    </g>
                  );
                })}
            </g>
          )}
        </g>
      )}
    </g>
  );
};

const FamilyTree = () => {
  const [showSpouse, setShowSpouse] = useState(true);
  const [familyTreeData, setFamilyTreeData] = useState(null);
  const [containerRef] = useCenteredTree(); // Custom hook for centering the tree

  useEffect(() => {
    const userId = 1; // You can set this to a dynamic value or get it from a user state
    fetchFamilyTreeData(userId).then((data) => setFamilyTreeData(data));
  }, []);

  if (!familyTreeData) {
    return <div>Loading family tree...</div>;
  }

  return (
    <div style={containerStyles}>
      <Tree
        data={familyTreeData}
        translate={{ x: 620, y: 320 }}
        renderCustomNodeElement={(rd3tProps) =>
          renderRectSvgNode({ ...rd3tProps, showSpouse, setShowSpouse })
        }
        orientation="vertical"
        pathFunc="step"
      />
    </div>
  );
};

export default FamilyTree;
