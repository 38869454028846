import React, { useState } from 'react';
import { styled } from '@mui/system';
import { TextField, Button, Container, Paper, Typography, LinearProgress } from '@mui/material';
import { sendOtp, updateOtp, verifyOtp, uidLogin } from '../config';

import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // For navigation
import API_BASE_URL from '../config';

// Styled components
const PageContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#f5f5f5',
});

const LoginBox = styled(Paper)({
  padding: '30px',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  width: '100%',
});

const ResponsiveTextField = styled(TextField)({
  marginBottom: '16px',
  width: '100%',
});

const LoginButton = styled(Button)({
  width: '100%',
  marginTop: '16px',
  backgroundColor: '#7c3aed',
  '&:hover': {
    backgroundColor: '#6d28d9',
  },
});

const ErrorMessage = styled(Typography)({
  color: 'red',
  marginTop: '10px',
});

const SettingAdd = () => {
  const [step, setStep] = useState(1); // Track the current step
  const [contactInfo, setContactInfo] = useState(''); // Mobile number or email
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [errors, setErrors] = useState({}); // Store validation errors
  const [passwordStrength, setPasswordStrength] = useState(0); // Password strength
  const [passwordMatch, setPasswordMatch] = useState(false); // Password match state

  const navigate = useNavigate(); // For redirecting to home

  // Regex for validation
  const phoneRegex = /^[0-9]{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Validate phone number or email
  const validateContactInfo = () => {
    if (!contactInfo) {
      return 'Please enter your mobile number or email';
    }
    if (!phoneRegex.test(contactInfo) && !emailRegex.test(contactInfo)) {
      return 'Please enter a valid mobile number or email';
    }
    return '';
  };

  // Simulate sending OTP
  const handleSendOtp  = async (e) =>  {
    const validationError = validateContactInfo();
    if (validationError) {
      setErrors({ contactInfo: validationError });
    } else {
      const data = await sendOtp(contactInfo);
      await updateOtp(contactInfo, data.data);
     // console.log(data);
      setStep(2); // Move to the next step after sending OTP
    }
  };

  // Simulate OTP verification
  const handleVerifyOtp = () => {
    if (otp === '1234') { // Simulating OTP '1234' as valid
      setIsOtpVerified(true);
      setStep(3); // Move to the next step after OTP verification
    } else {
      setErrors({ otp: 'Invalid OTP' });
    }
  };

  // Calculate password strength
  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 7) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/\d/.test(password)) strength += 1;
    if (/[@$!%*?&#]/.test(password)) strength += 1;
    return strength;
  };

  // Handle password inputs and calculate strength
  const handleNewPasswordChange = (event) => {
    const password = event.target.value;
    setNewPassword(password);
    setPasswordStrength(calculatePasswordStrength(password));
  };

  const handleConfirmPasswordChange = (event) => {
    const confirm = event.target.value;
    setConfirmPassword(confirm);
    setPasswordMatch(confirm === newPassword);
  };

  // Make API call to update password, either for phone_number/email or for UID
  const updatePassword = async () => {
    if (!passwordMatch) {
      setErrors({ confirmPassword: 'Passwords do not match' });
      return;
    }

    try {
      // First check if contactInfo (phone_number/email) exists in the user table
      const response = await axios.post(`${API_BASE_URL}/check-contact`, {
        contactInfo,
      });

      if (response.data.exists) {
        // If phone_number or email exists, update password for that user
        const updateResponse = await axios.post(`${API_BASE_URL}/update-password`, {
          contactInfo,
          newPassword,
        });

        if (updateResponse.status === 200) {
          localStorage.setItem('isLoggedIn', 'true');
          navigate('/home');
        }
      } else {
        // If phone_number or email doesn't exist, match with UID and update the password for UID
        const uidResponse = await axios.post(`${API_BASE_URL}/update-uid-password`, {
          uid: contactInfo, // Assuming the contactInfo field holds the UID here
          newPassword,
        });

        if (uidResponse.status === 200) {
          localStorage.setItem('isLoggedIn', 'true');
          navigate('/home');
        }
      }
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  return (
    <PageContainer>
      <LoginBox>
        <Typography variant="h5" gutterBottom>
          Set Your Password
        </Typography>

        {step === 1 && (
          <>
            <ResponsiveTextField
              label="Enter Mobile Number, Email Id"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
              error={!!errors.contactInfo}
              helperText={errors.contactInfo}
              variant="outlined"
            />
            <LoginButton variant="contained" onClick={handleSendOtp}>
              Send OTP
            </LoginButton>
          </>
        )}

        {step === 2 && (
          <>
            <ResponsiveTextField
              label="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              error={!!errors.otp}
              helperText={errors.otp}
              variant="outlined"
            />
            <LoginButton variant="contained" onClick={handleVerifyOtp}>
              Verify OTP
            </LoginButton>
          </>
        )}

        {step === 3 && isOtpVerified && (
          <>
            <ResponsiveTextField
              label="New Password"
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              variant="outlined"
            />
            <LinearProgress
              variant="determinate"
              value={(passwordStrength / 5) * 100}
              sx={{ marginTop: '16px' }}
            />
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: '8px' }}>
              {passwordStrength < 3 ? 'Weak Password' : passwordStrength === 3 ? 'Medium Strength' : 'Strong Password'}
            </Typography>

            <ResponsiveTextField
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!passwordMatch && confirmPassword.length > 0}
              helperText={!passwordMatch && confirmPassword.length > 0 ? 'Passwords do not match' : ''}
              variant="outlined"
            />
            <LoginButton
              variant="contained"
              onClick={updatePassword}
              disabled={!passwordMatch || passwordStrength < 3}
            >
              Update Password
            </LoginButton>
          </>
        )}
      </LoginBox>
    </PageContainer>
  );
};

export default SettingAdd;
