import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const jobTypes = [
    "Full-Time",
    "Part-Time",
    "Contract",
    "Internship",
    "Freelance",
];

const AddJobDetail = ({ editMode }) => {
    const [jobEntries, setJobEntries] = useState([
        { jobType: '', companyName: '', startDate: '', endDate: '', isPresent: false },
    ]);

    const handleAddJob = () => {
        setJobEntries([...jobEntries, { jobType: '', companyName: '', startDate: '', endDate: '', isPresent: false }]);
    };

    const handleJobChange = (index, field, value) => {
        const newJobEntries = [...jobEntries];
        newJobEntries[index][field] = value;

        if (field === 'isPresent' && value === true) {
            newJobEntries[index].endDate = '';
        }

        setJobEntries(newJobEntries);
    };

    const handleCheckboxChange = (index, e) => {
        handleJobChange(index, 'isPresent', e.target.checked);
    };

    const handleRemoveJob = (index) => {
        const newJobEntries = jobEntries.filter((_, i) => i !== index);
        setJobEntries(newJobEntries);
    };

    return (
        <div>
            {jobEntries.map((job, index) => (
                <div key={index} className="job-entry" style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                    
                    {/* Job Type */}
                    <div style={{ flex: 1, marginRight: '8px' }}>
                        <Autocomplete
                            freeSolo
                            options={jobTypes}
                            value={job.jobType}
                            onInputChange={(event, newInputValue) => handleJobChange(index, 'jobType', newInputValue)}
                            onChange={(event, newValue) => handleJobChange(index, 'jobType', newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Job Type"
                                    fullWidth
                                    margin="normal"
                                    disabled={!editMode}
                                />
                            )}
                        />
                    </div>

                    {/* Company Name */}
                    <div style={{ flex: 1, marginRight: '8px' }}>
                        <TextField
                            label="Company Name"
                            name="companyName"
                            value={job.companyName}
                            onChange={(e) => handleJobChange(index, 'companyName', e.target.value)}
                            fullWidth
                            margin="normal"
                            disabled={!editMode}
                        />
                    </div>

                    {/* Start Year */}
                    <div style={{ flex: 1, marginRight: '8px' }}>
                        <TextField
                            label="Start Year"
                            name="startYear"
                            type="number"
                            value={job.startYear}
                            onChange={(e) => handleJobChange(index, 'startYear', e.target.value)}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ min: 1900, max: new Date().getFullYear() }}
                            disabled={!editMode}
                        />
                    </div>

                    {/* End Year */}
                    {!job.isPresent && (
                        <div style={{ flex: 1, marginRight: '8px' }}>
                            <TextField
                                label="End Year"
                                name="endYear"
                                type="number"
                                value={job.endYear}
                                onChange={(e) => handleJobChange(index, 'endYear', e.target.value)}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ min: 1900, max: new Date().getFullYear() }}
                                disabled={!editMode}
                            />
                        </div>
                    )}

                    {/* Present Checkbox */}
                    <div style={{ flex: 1, marginRight: '8px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={job.isPresent}
                                    onChange={(e) => handleCheckboxChange(index, e)}
                                    name="isPresent"
                                    color="primary"
                                    disabled={!editMode}
                                />
                            }
                            label="Present"
                        />
                    </div>

                    {/* Remove Button */}
                    <div style={{ flex: 0 }}>
                        {index > 0 && (
                            <IconButton
                                color="secondary"
                                onClick={() => handleRemoveJob(index)}
                                sx={{ mt: 2 }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </div>
                </div>
            ))}

            {/* Add Job Button */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button onClick={handleAddJob} variant="contained" color="secondary" sx={{ mt: 2 }} disabled={!editMode}>
                    Add Work Details
                </Button>
            </div>
        </div>
    );
};

export default AddJobDetail;
