import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  isLoggedIn: false,
  user: null,
  role: 'user',
};

// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload;  // Set isLoggedIn
    },
    setUser(state, action) {
      state.user = action.payload.user;  // Store user information
      state.role = action.payload.role;  // Store user role
    },
    // You can add additional reducers if needed, e.g., for logging out
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.role = 'user'; // Reset to default role on logout
    },
  },
});

// Export actions
export const { setIsLoggedIn, setUser, logout } = authSlice.actions;

// Export reducer
export default authSlice.reducer;
