import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import API_BASE_URL from '../config';
const NotificationsMenu = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const uid = localStorage.getItem('uid');
    try {
      // Call the logout API on the server to destroy the session
      await axios.post(`${API_BASE_URL}/logout`, { uid });
  
      // Remove the isLoggedIn flag from localStorage and sessionStorage
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('uid');
      sessionStorage.clear();
  
      // Redirect to the login page
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const notifications = [
  
   
    { text: 'Logout', route: handleLogout }, // Use the logout function directly
  ];

  const handleNotificationClick = (route) => {
    if (typeof route === 'function') {
      route(); // Call the logout function
    } else {
      navigate(route); // Navigate to the route
    }
  };

  return (
    <ul>
      {notifications.map((notification, index) => (
        <li key={index}>
         <Tooltip title="Logout">
            <LogoutOutlinedIcon onClick={() => handleNotificationClick(notification.route)} sx= {{ cursor: 'pointer'}}/>
          </Tooltip>
        </li>
      ))}
    </ul>
  );
};

export default NotificationsMenu;
