import React, { useState } from 'react';
import { Autocomplete, TextField, Button } from '@mui/material';
import axios from 'axios';
import API_BASE_URL from '../config';
import Tree from 'react-d3-tree';

const UserSearch = () => {
  const [user1, setUser1] = useState('');
  const [user2, setUser2] = useState('');
  const [uid1, setUid1] = useState('');
  const [uid2, setUid2] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [relationship, setRelationship] = useState(null); // Store the relationship
  const [relationshipPathData, setRelationshipPathData] = useState(null); // Path between the two users

  // Handle search suggestions
  const handleSearch = async (index, field, value) => {
    if (value) {
      try {
        const response = await axios.get(`${API_BASE_URL}/search`, {
          params: { [field]: value }
        });
        setSuggestions(response.data.length > 0 ? response.data : []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleFieldChange = (index, field, value) => {
    if (index === 0) {
      setUser1(value);
      setIsSearchEnabled(value && user2);
    } else {
      setUser2(value);
      setIsSearchEnabled(user1 && value);
    }
  };

  const handleSuggestionSelect = (index, selectedOption) => {
    if (index === 0) {
      setUid1(selectedOption.id);
      setUser1(`${selectedOption.first_name} (UID: ${selectedOption.uid})`);
    } else {
      setUid2(selectedOption.id);
      setUser2(`${selectedOption.first_name} (UID: ${selectedOption.uid})`);
    }
  };

  // Handle search click to find the relationship path
  const handleSearchClick = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/find-relationship`, {
        uid1,
        uid2,
      });
      if (response.data.relationship.relationship && response.data.relationship.connectingNodes.length > 0) {
        const connectingNodes  = response.data.relationship.connectingNodes;
        const relationship  = response.data.relationship.relationship;

console.log('========1=====>>>>',relationship);
        console.log('=====2========>>>>', connectingNodes);
        // Transform path data into a tree structure suitable for `react-d3-tree`
        const transformedData = transformPathToTree(connectingNodes);

        // Set the relationship and path data for display
        setRelationship(relationship);
        setRelationshipPathData(transformedData);
      } else {
        setRelationshipPathData(null);
        alert('No relation found between the selected users.');
      }
    } catch (error) {
      console.error('Error fetching relationship:', error);
      alert('Error fetching relationship. Please try again.');
    }
  };

// Transform the relationship path into tree structure
const transformPathToTree = (paths) => {
  if (!paths || paths.length === 0) return null;

  // Create a root node to contain all paths
  const rootNode = {
      name: 'Root',
      children: [],
  };

  // Iterate over each path
  paths.forEach(path => {
      let currentNode = rootNode;

      // Build a tree for the current path
      path.forEach((node, index) => {
          // Create the new node
          const newNode = {
              name: node.name, // Use the name of the person
              // attributes: node.attributes,
              // spouse: node.spouse,
              children: [],
          };

          // Check if the node already exists in the current path
          const existingNode = currentNode.children.find(child => child.name === newNode.name);

          if (existingNode) {
              currentNode = existingNode; // Move to the existing node
          } else {
              currentNode.children.push(newNode); // Add the new node
              currentNode = newNode; // Move to the new node
          }
      });
  });

  return rootNode; // Return the root node which represents the tree
};


  return (
    <div className="container mt-4">
      {/* User Search UI */}
      <div className="row">
        <div className="col-md-6">
          <Autocomplete
            freeSolo
            options={suggestions.map((option) => `${option.first_name} (UID: ${option.uid})`)}
            inputValue={user1}
            onInputChange={(event, newInputValue) => {
              handleSearch(0, 'first_name', newInputValue);
              handleFieldChange(0, 'first_name', newInputValue);
            }}
            onChange={(event, newValue) => {
              const selectedOption = suggestions.find(
                (option) => `${option.first_name} (UID: ${option.uid})` === newValue
              );
              if (selectedOption) {
                handleSuggestionSelect(0, selectedOption);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User 1"
                fullWidth
                margin="normal"
              />
            )}
          />
        </div>

        <div className="col-md-6">
          <Autocomplete
            freeSolo
            options={suggestions.map((option) => `${option.first_name} (UID: ${option.uid})`)}
            inputValue={user2}
            onInputChange={(event, newInputValue) => {
              handleSearch(1, 'first_name', newInputValue);
              handleFieldChange(1, 'first_name', newInputValue);
            }}
            onChange={(event, newValue) => {
              const selectedOption = suggestions.find(
                (option) => `${option.first_name} (UID: ${option.uid})` === newValue
              );
              if (selectedOption) {
                handleSuggestionSelect(1, selectedOption);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User 2"
                fullWidth
                margin="normal"
              />
            )}
          />
        </div>

        <div className="col-12 text-center mt-4">
          <Button
            onClick={handleSearchClick}
            variant="contained"
            color="primary"
            disabled={!isSearchEnabled}
          >
            Search
          </Button>
        </div>
      </div>

      {/* Display Relationship */}
      {relationship && (
        <div className="mt-4">
          <h4 className="text-center">Relationship: {relationship}</h4>
        </div>
      )}

      {/* Relationship Path Graph */}
      <div className="mt-4" style={{ height: '500px', width: '100%' }}>
        {relationshipPathData && (
          <Tree
            data={relationshipPathData}
            orientation="vertical"
            translate={{ x: 500, y: 50 }}
            pathFunc="step" // Creates a staircase-like path
            zoomable={true}
            scaleExtent={{ min: 0.5, max: 1.3 }}
            separation={{ siblings: 1.5, nonSiblings: 2 }}
          />
        )}
      </div>
    </div>
  );
};

export default UserSearch;
