import React, { useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';
import { ReactComponent as MyIconF } from '../m.svg';
import { ReactComponent as MyIconM } from '../f.svg';
import MatchesList from './MatchesList'; // Import the MatchesList component with pagination

const FindPartner = () => {
  const [showGenderOptions, setShowGenderOptions] = useState(false);
  const [selectedGender, setSelectedGender] = useState('');
  const [showAgeGroups, setShowAgeGroups] = useState(false);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('');
  const [matches, setMatches] = useState([]);

  const ageGroups = [
    { label: '18-22', min: 18, max: 22 },
    { label: '23-26', min: 23, max: 26 },
    { label: '27-31', min: 27, max: 31 },
    { label: '32-36', min: 32, max: 36 },
    { label: '37-41', min: 37, max: 41 },
    { label: '42-80', min: 42, max: 80 },
  ];

  const handleFindPartnerClick = () => {
    setShowGenderOptions(true);
  };

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setShowAgeGroups(true);
  };

  const handleAgeGroupSelect = (group) => {
    setSelectedAgeGroup(group.label);
    searchMatches(selectedGender, group);
  };

  const searchMatches = async (gender, ageGroup) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/find-match`, {
        params: {
          gender,
          minAge: ageGroup.min,
          maxAge: ageGroup.max,
        },
      });
      console.log(response.data);
      setMatches(response.data); // Update matches with fetched data
    } catch (error) {
      console.error('Error fetching matches:', error);
    }
  };

  return (
    <div className="find-match">
      <div className="text-center mt-5">
        <h2 className="mb-3">Find Your Match</h2>
        <p className="mb-4">Click the button below to start searching for a partner!</p>
        <button className="btn btn-primary btn-lg" onClick={handleFindPartnerClick}>
          Find Match
        </button>

        {showGenderOptions && (
          <div className="gender-options mt-4">
            <h4 className="mb-3">Select Gender</h4>
            <div className="d-flex justify-content-center gap-3">
              <button
                className={`btn btn-outline-secondary btn-lg ${selectedGender === 'Male' ? 'active' : ''}`}
                onClick={() => handleGenderSelect('Male')}
              >
                Male
              </button>
              <button
                className={`btn btn-outline-secondary btn-lg ${selectedGender === 'Female' ? 'active' : ''}`}
                onClick={() => handleGenderSelect('Female')}
              >
                Female
              </button>
            </div>
          </div>
        )}

        {showAgeGroups && (
          <div className="age-group-options mt-4">
            <h4 className="mb-3">Select Age Group</h4>
            <div className="d-flex justify-content-center gap-3 flex-wrap">
              {ageGroups.map((group) => (
                <button
                  key={group.label}
                  className={`btn btn-outline-secondary btn-lg ${selectedAgeGroup === group.label ? 'active' : ''}`}
                  onClick={() => handleAgeGroupSelect(group)}
                >
                  {group.label}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Render MatchesList component with pagination */}
        {matches.length > 0 && <MatchesList matches={matches} />}
      </div>
    </div>
  );
};

export default FindPartner;
