import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Guest from './pages/Guest';
import SignupPage from './pages/SignupPage';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Help from './pages/Help';
import PrivateRoute from './pages/PrivateRoute';
import ErrorPage from './pages/ErrorPage';
import MyFamilyTree from './pages/MyFamilyTree';
import MyFamily from './pages/MyFamily';
import FindRelation from './pages/FindRelation';
import Gallery from './pages/Gallery'
import Setting from './pages/Setting'
import Partner from './pages/Partner'

function App() {

  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  return (
    <Router basename='/goldpetals'>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/guest" element={<Guest />} />
        <Route path="/signup" element={<SignupPage />} />

        {/* Private route for logged-in users */}
        <Route element={<PrivateRoute isLoggedIn={isLoggedIn} />}>
        <Route path="/find-partner" element={<Partner/> }/>
          <Route path="/find-relation" element={<FindRelation />} />
          <Route path="/my-family-tree" element={<MyFamily />} />
          <Route path="/find-people" element={<Home />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/home" element={<MyFamilyTree />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/help" element={<Help />} />
          <Route path="/picture-gallery" element={<Gallery />} />
        </Route>
        <Route path="/logout" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
