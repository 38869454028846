import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

import API_BASE_URL from '../config'; // Replace with your API base URL

const UserProfileDialog = ({ selectedUser, openProfileDialog, handleProfileClose }) => {
    const [photos, setPhotos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [photosPerPage, setPhotosPerPage] = useState(5); // Number of images per page
    const [selectedImage, setSelectedImage] = useState(null); // To track the selected image for gallery view

    const fetchPhotos = async (userId) => {
        try {
            // Pass userId as a query parameter to the API
            const response = await axios.get(`${API_BASE_URL}/photos`, {
                params: { userId },
            });

            console.log('Fetched photos:', response.data);
            setPhotos(response.data); // Update the state with the fetched photos
        } catch (error) {
            console.error('Error fetching photos:', error);
        }
    };

    // Fetch photos when the selectedUser changes and dialog opens
    useEffect(() => {
        if (selectedUser) {
            fetchPhotos(selectedUser.id); // Fetch photos for the selected user
        }
    }, [selectedUser, openProfileDialog]);

    // Pagination logic
    const indexOfLastPhoto = currentPage * photosPerPage;
    const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
    const currentPhotos = photos.slice(indexOfFirstPhoto, indexOfLastPhoto);

    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    // Gallery modal for image
    const openGallery = (image) => {
        setSelectedImage(image);
    };

    const handleCloseGallery = () => {
        setSelectedImage(null);
    };

    return (
        selectedUser && (
            <Dialog open={openProfileDialog} onClose={handleProfileClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {selectedUser.first_name} {selectedUser.last_name}
                    <IconButton
                        aria-label="close"
                        onClick={handleProfileClose}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {selectedUser.image_url ? (
                        <img
                            src={selectedUser.image_url}
                            alt={`${selectedUser.first_name} ${selectedUser.last_name}`}
                            style={{ width: '100px', height: 'auto', marginBottom: '16px', borderRadius: '50%' }}
                        />
                    ) : (
                        <Avatar sx={{ width: 100, height: 100 }}>{selectedUser.first_name[0]}</Avatar>
                    )}

                    <Typography variant="body1" gutterBottom>
                        <strong>Full Name:</strong> {selectedUser.first_name} {selectedUser.last_name}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Email:</strong> {selectedUser.email_id || 'N/A'}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Phone:</strong> {selectedUser.phone_number || 'N/A'}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Address:</strong> {selectedUser.area}, {selectedUser.district}, {selectedUser.state} - {selectedUser.pincode}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Age:</strong> {selectedUser.age || 'N/A'}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>Gender:</strong> {selectedUser.gender || 'N/A'}
                    </Typography>

                    {/* Render user photos */}
                    {photos.length > 0 && (
                        <div style={{ marginTop: '16px' }}>
                            <Typography variant="h6" gutterBottom>
                                Photos:
                            </Typography>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                {currentPhotos.filter(photo => photo.status === 1).map((photo) => (
                                    <img
                                        key={photo.id}
                                        src={`https://family.seatechdigital.com/goldpetals/uploads/${photo.filename}`} // Assuming each photo object has a `filename` field
                                        alt="User Photo"
                                        style={{ width: '80px', height: '80px', borderRadius: '8px', objectFit: 'cover', cursor: 'pointer' }}
                                        onClick={() => openGallery(photo)} // Open gallery on click
                                    />
                                ))}
                            </div>
                            {/* Pagination */}
                            <DialogActions>
                                <Pagination
                                    count={Math.ceil(photos.length / photosPerPage)}
                                    page={currentPage}
                                    onChange={paginate}
                                    color="primary"
                                />
                            </DialogActions>
                        </div>
                    )}
                </DialogContent>

                {/* Gallery Modal */}
                <Dialog
                    open={Boolean(selectedImage)}
                    onClose={handleCloseGallery}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle>
                        Image Gallery
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseGallery}
                            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {selectedImage && (
                            <img
                                src={`https://family.seatechdigital.com/goldpetals/uploads/${selectedImage.filename}`}
                                alt="Selected User Photo"
                                style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            </Dialog>
        )
    );
};

export default UserProfileDialog;
