import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { People } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const FindPartnerButton = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/find-partner'); // Redirect to the /home route
    };

    // Destructure match from props so it doesn't get passed to DOM
    const StyledListItemButton = styled(({ match, ...other }) => (
        <ListItemButton {...other} />
    ))(({ theme, match }) => ({
        backgroundColor: match ? theme.palette.primary.light : 'transparent',
        '&:hover': {
            backgroundColor: match ? theme.palette.primary.main : theme.palette.action.hover,
        },
    }));

    const isHome = location.pathname; // This can be dynamic based on your use case

    return (
        <StyledListItemButton onClick={handleHomeClick} match={isHome === '/find-partner'}>
            <ListItemIcon>
                <People />
            </ListItemIcon>
            <ListItemText primary="Find Partner" />
        </StyledListItemButton>
    );
};

export default FindPartnerButton;



