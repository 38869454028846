import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { TextField, Typography, Button, Container, Paper, Box, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import '../assets/css/style.css';
import bg from '../assets/images/bg.jpeg';
import { sendOtp, updateOtp, verifyOtp, uidLogin } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoggedIn, setUser } from '../store/authSlice';
// Styled components
const PageContainer = styled(Container)({
  display: 'flex',
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: '50px',
  backgroundImage: `url(${bg})`,
  backgroundPositionY: '-75px',
  backgroundPositionX: '-286px',
});

const LoginBox = styled(Paper)({
  padding: '30px',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  width: '100%',
});

const Logo = styled(Typography)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#7c3aed',
  marginBottom: '20px',
});

const ResponsiveTextField = styled(TextField)({
  marginBottom: '16px',
  width: '100%',
});

const LoginButton = styled(Button)({
  width: '100%',
  marginTop: '16px',
  backgroundColor: '#7c3aed',
  '&:hover': {
    backgroundColor: '#6d28d9',
  },
});

const ErrorMessage = styled(Typography)({
  color: 'red',
  marginTop: '10px',
});

const LoginPage = () => {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(90);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isUidLogin, setIsUidLogin] = useState(false);
  const [email_id, setEmail] = useState('');
  const [phone_number, setPhone] = useState('');
  const [uid, setUid] = useState('');

  useEffect(() => {
    if (isOTPSent && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [isOTPSent, timer]);

  // Validation functions
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());
  const validatePhone = (phone) => /^\d{10}$/.test(phone);
  const validateUid = (uid) => /^91[A-Z]{2}\d{14,20}$/.test(uid);

  const validateInput = (input) => {
    return validateEmail(input) || validatePhone(input) || validateUid(input);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateInput(input)) {
      setMessage('Please enter a valid email address, phone number, or unique ID.');
      return;
    }

    if (validateUid(input)) {
      setIsUidLogin(true);
    } else {
      setIsUidLogin(false);
      if (!isOTPSent) {
        try {
          const data = await sendOtp(input);
          if (data.message === 'Create Account First') {
            setMessage(data.message);
          } else {
            setPhone(input);
            setEmail(input);
            setUid(input);
            setMessage(data.message);
            const otp = data.data;
            await updateOtp(input, otp);
            setOtp(otp);
            setIsOTPSent(true);
            setTimer(90);
          }
        } catch (error) {
          setMessage('Error sending OTP.');
        }
      } else {
        verifyOTP(e);
      }
    }
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    if (otp.length !== 4) {
      setMessage('Please enter a valid 4-digit OTP.');
      return;
    }

    try {
      const data = await verifyOtp(email_id, phone_number, otp);
      if (data.redirectTo) {
        dispatch(setIsLoggedIn(true));
        dispatch(setUser(data));
                localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('uid', data.uid);
        window.location.href = data.redirectTo;
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      setMessage('Error verifying OTP.');
    }
  };

  const handleUidLogin = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setMessage('Password must be at least 6 characters long.');
      return;
    }

    try {
      const data = await uidLogin(input, password);
      if (data.redirectTo) {
        localStorage.setItem('isLoggedIn', 'true');
        window.location.href = data.redirectTo;
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      setMessage('Error logging in with UID.');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (validatePhone(value) || validateEmail(value) || validateUid(value)) {
      setInput(value);
    } else if (/^\d*$/.test(value) && value.length <= 10) {
      setInput(value);
    } else if (validateEmail(value) || validateUid(value)) {
      setInput(value);
    }
  };

  const handleOTPChange = (e) => setOtp(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <PageContainer maxWidth={false}>
      <LoginBox elevation={3} style={{ marginRight: '18%' }}>
        <Logo>GOLD PETALS</Logo>
        <Typography variant="h5" gutterBottom>
          Welcome to Gold Petals! 👋🏻
        </Typography>

        <form onSubmit={isUidLogin ? handleUidLogin : handleLogin} method='POST'>
          <ResponsiveTextField
            label="Mobile Number/ Email ID/ Unique ID"
            variant="outlined"
            type="text"
            value={input}
            onChange={handleInputChange}
            required
          />

          {!isUidLogin && isOTPSent && (
            <>
              <ResponsiveTextField
                label="Enter OTP"
                variant="outlined"
                type="text"
                value={otp}
                onChange={handleOTPChange}
                required
              />
              <Typography variant="body2" color="textSecondary">
                {timer > 0
                  ? `Resend OTP in ${timer}s`
                  : <Typography component="span" color="primary" onClick={handleLogin}>Resend OTP</Typography>}
              </Typography>
            </>
          )}

          {isUidLogin && (
            <ResponsiveTextField
              label="Enter Password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      onClick={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {message && <ErrorMessage>{message}</ErrorMessage>}
          <LoginButton variant="contained" type="submit">
            {isUidLogin ? 'Log In' : isOTPSent ? 'Verify OTP' : 'Get OTP'}
          </LoginButton>
        </form>
        <Box display="flex" flexDirection="column" alignItems="center" style={{ marginTop: '24px' }}>
          <Typography variant="body2" gutterBottom>
            New on our platform? <Typography component="span" color="primary"><a href='/goldpetals/signup' style={{ textDecoration: 'none' }}> Create an account</a></Typography>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <Typography component="span" color="primary"><a href='/goldpetals/guest' style={{ textDecoration: 'none' }}>Guest Login</a></Typography>
          </Typography>
        </Box>
      </LoginBox>
    </PageContainer>
  );
};

export default LoginPage;
