import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/system';
import {
  TextField,
  Typography,
  Button,
  Container,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';
import debounce from 'lodash.debounce';
import bg from '../assets/images/bg.jpeg'; // Adjust the path as necessary


const PageContainer = styled(Container)({
  display: 'flex',
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
});

const RegisterBox = styled(Paper)({
  padding: '30px',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  width: '100%',
});

const Logo = styled(Typography)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#7c3aed',
  marginBottom: '20px',
});

const ResponsiveTextField = styled(TextField)({
  marginBottom: '16px',
  width: '100%',
});

const RegisterButton = styled(Button)({
  width: '100%',
  marginTop: '16px',
  backgroundColor: '#7c3aed',
  '&:hover': {
    backgroundColor: '#6d28d9',
  },
});

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    prefix: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    display_name: '',
    phone_number: '',
    email_id: '',
    address: '',
    area: '',
    district: '',
    state: '',
    pincode: '',
  });

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [otpSentTo, setOtpSentTo] = useState(null); // 'phone' or 'email'
  const [pincode, setPincode] = useState();
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState(null);


  // Define the debounced function
  const fetchData = useCallback(
    debounce(async (pin) => {
      try {
        const response = await axios.get(`${API_BASE_URL}/fetch-city?pincode=${pin}`);
        // console.log(response.data);
        setFetchedData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 300), // Debounce delay
    []
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'pincode') {
      setPincode(value);

      // Fetch data only if the pincode has 6 digits
      if (value.length === 6) {
        fetchData(value);
      } else {
        setFetchedData([]);
      }
    }


    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVillageChange = (event) => {
    const villageName = event.target.value;

    // Adjust key names to match the correct ones from your database fields
    const selected = fetchedData.find(item => item['village_name'] === villageName);

    // Set the selected village
    setSelectedVillage(selected);

    // Update the form data with the selected village details
    setFormData({
      ...formData,
      area: selected ? selected['subdistrict_name'] : '',
      state: selected ? selected['state_name'] : '',
      district: selected ? selected['district_name'] : '',
    });
  };


  const validatephone_number = (phoneNumber) => {
    return /^\d{10}$/.test(phoneNumber);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSendOtp = (type) => {
    const data = type === 'phone' ? { phone_number: formData.phone_number } : { email_id: formData.email_id };
    axios.post(`${API_BASE_URL}/send-otp`, data)
      .then((response) => {
        setOtpSentTo(type);
        alert(response.data.message);
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
      });
  };

  const handleVerifyOtp = (type) => {
    const otp = type === 'phone' ? formData.otpPhone : formData.otpEmail;
    const data = { otp, type, phone_number: formData.phone_number, email_id: formData.email_id };
    axios.post(`${API_BASE_URL}/verify-otp`, data)
      .then((response) => {
        if (type === 'phone') {
          setIsPhoneVerified(true);
        } else {
          setIsEmailVerified(true);
        }
        alert('OTP verified successfully!');
      })
      .catch((error) => {
        console.error('Error verifying OTP:', error);
        alert('OTP verification failed. Please try again.');
      });
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();




    const stateAbbreviations = {
      "Andaman and Nicobar Islands": "AN",
      "Andhra Pradesh": "AP",
      "Arunachal Pradesh": "AR",
      "Assam": "AS",
      "Bihar": "BR",
      "Chandigarh": "CH",
      "Chhattisgarh": "CT",
      "Dadra and Nagar Haveli and Daman and Diu": "DN",
      "Delhi": "DL",
      "Goa": "GA",
      "Gujarat": "GJ",
      "Haryana": "HR",
      "Himachal Pradesh": "HP",
      "Jammu and Kashmir": "JK",
      "Jharkhand": "JH",
      "Karnataka": "KA",
      "Kerala": "KL",
      "Ladakh": "LA",
      "Lakshadweep": "LD",
      "Madhya Pradesh": "MP",
      "Maharashtra": "MH",
      "Manipur": "MN",
      "Meghalaya": "ML",
      "Mizoram": "MZ",
      "Nagaland": "NL",
      "Odisha": "OD",
      "Puducherry": "PY",
      "Punjab": "PB",
      "Rajasthan": "RJ",
      "Sikkim": "SK",
      "Tamil Nadu": "TN",
      "Telangana": "TG",
      "Tripura": "TR",
      "Uttar Pradesh": "UP",
      "Uttarakhand": "UK",
      "West Bengal": "WB"
    };


    //  let lastGeneratedId = 1; // This should be fetched from the database or stored persistently
    const generateUniqueId = (data) => {
      const stateAbbreviation = stateAbbreviations[data['state_name']] || 'XX'; // Default to 'XX' if not found
      const districtCode = data['district_code'];
      const villageCode = data['village_code'];

      // Use the updated and correctly padded `lastGeneratedId`
      const incrementalId = Math.floor(100000 + Math.random() * 900000); // Generates a random 6-digit number


      // Combine all parts to form the unique ID
      const uniqueId = `91${stateAbbreviation}${districtCode}${villageCode}${incrementalId}`;
      console.log('Generated Unique ID:', uniqueId);

      return uniqueId;
    };



    const uniqueId = generateUniqueId(selectedVillage);
    //console.log('==================>>>>>>><<<<<<<<<==================', uniqueId); // Example output: "91MH603645015000001"


    // Merge formData with dynamically selected village data
    const finalFormData = {
      ...formData,
      uid: uniqueId,
      area: selectedVillage ? selectedVillage['subdistrict_name'] : formData.area,
      state: selectedVillage ? selectedVillage['state_name'] : formData.state,
      district: selectedVillage ? selectedVillage['district_name'] : formData.district,
      address: formData.address || '', // Ensure address is included
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/signup`, finalFormData);
      console.log('User saved:', response.data);
      navigate('/login/');
    } catch (err) {
      console.error('Error saving user data:', err);
    }
  };

  return (
    <PageContainer maxWidth={false}>
      <Grid container spacing={2}  style={{ minHeight: '100vh', backgroundImage: `url(${bg})`,   backgroundPositionY: '-75px', backgroundPositionX: '-286px', }}>
        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* Add your illustration or statistics here */}
        </Grid>
        <Grid item xs={12} md={6} className="centered-grid-item">
          <RegisterBox elevation={3}>
            <Logo>GOLD PETALS</Logo>
            <Typography variant="h5" gutterBottom className="mb-3">
              Create Your Account
            </Typography>

            <form onSubmit={handleSubmit}>
              <ResponsiveTextField
                select
                label="Prefix"
                name="prefix"
                value={formData.prefix}
                onChange={handleInputChange}
                variant="outlined"
                required
              >
                <MenuItem value="Mr">Mr</MenuItem>
                <MenuItem value="Mrs">Mrs</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
                <MenuItem value="Miss">Miss</MenuItem>
                <MenuItem value="Master">Master</MenuItem>
              </ResponsiveTextField>

              <ResponsiveTextField
                required
                label="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                variant="outlined"
              />

              <ResponsiveTextField
                label="Middle Name"
                name="middle_name"
                value={formData.middle_name}
                onChange={handleInputChange}
                variant="outlined"
              />

              <ResponsiveTextField
                required
                label="Last Name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                variant="outlined"
              />

              <ResponsiveTextField
                required
                label="Display Name"
                name="display_name"
                value={formData.display_name}
                onChange={handleInputChange}
                variant="outlined"
              />

              <ResponsiveTextField
                required
                label="Phone Number"
                name="phone_number"
                value={formData.phone_number || ''}
                onChange={(e) => {
                  const phone_number = e.target.value;
                  if (/^\d{0,10}$/.test(phone_number)) {
                    handleInputChange(e);
                  }
                }}
                variant="outlined"
                fullWidth
                disabled={isPhoneVerified}
                inputProps={{
                  maxLength: 10,
                  inputMode: 'numeric',
                }}
              />
              {!isPhoneVerified && (
                <>
                  {otpSentTo === 'phone' && (
                    <>
                      <TextField
                        required
                        label="Enter OTP"
                        name="otpPhone"
                        value={formData.otpPhone || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                      />
                      <Button
                        variant="contained"
                        onClick={() => handleVerifyOtp('phone')}
                      >
                        Verify Phone OTP
                      </Button>
                    </>
                  )}
                </>
              )}
              <ResponsiveTextField
                required
                label="Email"
                name="email_id"
                value={formData.email_id || ''}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                disabled={isEmailVerified}
              />
              {!isEmailVerified && (
                <>
                  {otpSentTo === 'email_id' && (
                    <>
                      <TextField
                        required
                        label="Enter OTP"
                        name="otpEmail"
                        value={formData.otpEmail || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                      />
                      <Button
                        variant="contained"
                        onClick={() => handleVerifyOtp('email_id')}
                      >
                        Verify Email OTP
                      </Button>
                    </>
                  )}
                </>
              )}

              <TextField
                required
                label="Pin"
                name="pincode"
                value={pincode}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: 6,
                  inputMode: 'numeric',
                }}
              />

              {fetchedData.length > 0 && (
                <FormControl fullWidth margin="normal">
                  <InputLabel id="village-select-label">Select Village</InputLabel>
                  <Select
                    labelId="village-select-label"
                    id="village-select"
                    value={selectedVillage ? selectedVillage['village_name'] : ''}
                    onChange={handleVillageChange}
                    label="Select Village"
                  >
                    {fetchedData.map((item, index) => (
                      <MenuItem key={index} value={item['village_name']}>
                        {item['village_name']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}


              {selectedVillage && (
                <>
                  <ResponsiveTextField
                    required
                    label="State"
                    name="state"
                    value={selectedVillage['state_name']} // Update to match database key
                    variant="outlined"
                    disabled
                  />
                  <ResponsiveTextField
                    required
                    label="District"
                    name="district"
                    value={selectedVillage['district_name']} // Update to match database key
                    variant="outlined"
                    disabled
                  />
                  <ResponsiveTextField
                    required
                    label="Subdistrict"
                    name="area"
                    value={selectedVillage['subdistrict_name']} // Update to match database key
                    variant="outlined"
                    disabled
                  />
                  <ResponsiveTextField
                    required
                    label="House No./ Plot No./ Sector"
                    name="address"
                    value={formData.address || ''}
                    onChange={handleInputChange}
                    variant="outlined"
                    placeholder="Enter your address"
                    fullWidth
                  />
                </>
              )}


              <RegisterButton variant="contained" type="submit">
                Register
              </RegisterButton>
            </form>

            <Grid container direction="column" alignItems="center" style={{ marginTop: '24px' }}>
              <Typography variant="body2" gutterBottom>
                Already have an account?{' '}
                <Typography component="span" color="primary">
                  <a href="/goldpetals/login">Log In</a>
                </Typography>
              </Typography>
            </Grid>
          </RegisterBox>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default RegisterForm;
