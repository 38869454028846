import React, { useState } from 'react';
import { ReactComponent as MyIconF } from '../m.svg';
import { ReactComponent as MyIconM } from '../f.svg';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Pagination,
    Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MatchesList = ({ matches }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);
    const usersPerPage = 9;

    const totalPages = Math.ceil(matches.length / usersPerPage);
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = matches.slice(indexOfFirstUser, indexOfLastUser);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleViewProfile = (user) => {
        setSelectedUser(user); // Set the selected user to view the profile
    };

    const handleCloseModal = () => {
        setSelectedUser(null); // Close the modal
    };

    return (
        <Box sx={{ mt: 4 }}>
            {matches.length > 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" gutterBottom>
                        Matching Users
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
                        {currentUsers.map((user) => (
                            <Card key={user.uid} sx={{ width: 300 }}>
                                {user.image_url ? (
                                    <CardMedia
                                        component="img"
                                        height="250"
                                        image={user.image_url}
                                        alt={`${user.first_name} ${user.last_name}`}
                                        sx={{ objectFit: 'cover' }}
                                    />
                                ) : user.gender === 'Male' ? (
                                    <Box sx={{ height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <MyIconM width="150" height="150" />
                                    </Box>
                                ) : (
                                    <Box sx={{ height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <MyIconF width="150" height="150" />
                                    </Box>
                                )}

                                <CardContent>
                                    <Typography variant="h6">
                                        {user.first_name} {user.last_name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        <strong>Age:</strong> {user.age} years
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        <strong>State:</strong> {user.state}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        <strong>District:</strong> {user.district}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ mt: 2 }}
                                        onClick={() => handleViewProfile(user)}
                                        fullWidth
                                    >
                                        View Profile
                                    </Button>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>

                    {/* Pagination Controls */}
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ mt: 4 }}
                    />
                </Box>
            )}

            {/* Modal to display the full profile */}
            {selectedUser && (
                <Dialog open={!!selectedUser} onClose={handleCloseModal} fullWidth maxWidth="sm">
                    <DialogTitle>
                        {selectedUser.first_name} {selectedUser.last_name}
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseModal}
                            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        {selectedUser.image_url ? (
                            <img
                                src={selectedUser.image_url}
                                alt={`${selectedUser.first_name} ${selectedUser.last_name}`}
                                style={{ width: '100%', height: 'auto', marginBottom: '16px' }}
                            />
                        ) : selectedUser.gender === 'Male' ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                <MyIconM width="150" height="150" />
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                                <MyIconF width="150" height="150" />
                            </Box>
                        )}

                        {/* <Typography variant="body1" gutterBottom>
                            <strong>Prefix:</strong> {selectedUser.prefix || 'N/A'}
                        </Typography> */}
                        <Typography variant="body1" gutterBottom>
                            <strong>Full Name:</strong> {`${selectedUser.prefix } ${selectedUser.first_name} ${selectedUser.middle_name || ''} ${selectedUser.last_name}`}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Pet Name:</strong> {selectedUser.display_name || 'N/A'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Phone Number:</strong> {selectedUser.phone_number || 'N/A'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Email:</strong> {selectedUser.email_id || 'N/A'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Address:</strong> {`${selectedUser.address}, ${selectedUser.area}, ${selectedUser.state}, ${selectedUser.district} - ${selectedUser.pincode}` || 'N/A'}
                        </Typography>
                       
                        <Typography variant="body1" gutterBottom>
                            <strong>Age:</strong> {selectedUser.age || 'N/A'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Date of Birth:</strong> {selectedUser.dob || 'N/A'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Gender:</strong> {selectedUser.gender || 'N/A'}
                        </Typography>
                        {/* <Typography variant="body1" gutterBottom>
                            <strong>Position:</strong> {selectedUser.position || 'N/A'}
                        </Typography> */}
                    </DialogContent>

                </Dialog>
            )}
        </Box>
    );
};

export default MatchesList;
