import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Button, Autocomplete, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import API_BASE_URL from '../config';

const AddFamilyMember = ({ formData, editMode }) => {
  const [additionalFields, setAdditionalFields] = useState([
    { relation: 'Self', firstname: formData.first_name, lastname: formData.last_name, position: formData.position, uid: formData.uid, gender: '' }
  ]);
  const [positions, setPositions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const handleAddField = () => {
    setAdditionalFields([...additionalFields, { relation: '', firstname: '', lastname: '', position: '', uid: '', gender: '' }]);
  };

  const handleRemoveField = (index) => {
    const newFields = [...additionalFields];
    newFields.splice(index, 1);
    setAdditionalFields(newFields);
  };

  const handleFieldChange = (index, field, value) => {
    const newFields = [...additionalFields];
    newFields[index][field] = value;
    setAdditionalFields(newFields);
  };

  const handleSuggestionSelect = (index, option) => {
    const newFields = [...additionalFields];
    newFields[index].firstname = option.first_name;
    newFields[index].lastname = option.last_name;
    newFields[index].uid = option.uid;
    setAdditionalFields(newFields);
  };

  const handleSearch = async (index, field, value) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/search`, { params: { query: value } });
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  return (
    <div>
      {additionalFields.map((field, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          {/* Relationship */}
          <TextField
            select
            label="Relationship"
            name="relationship"
            value={field.relation}
            onChange={(e) => handleFieldChange(index, 'relation', e.target.value)}
            fullWidth
            margin="normal"
            style={{ flex: 1, marginRight: '8px' }}
            disabled={index === 0 || !editMode}
          >
            {['Self', 'Father', 'Mother', 'Husband', 'Wife'].map((relation, i) => (
              <MenuItem key={i} value={relation}>
                {relation}
              </MenuItem>
            ))}
          </TextField>

          {/* First Name */}
          <TextField
            label="First Name"
            value={index === 0 ? formData.first_name : field.firstname}
            onChange={(e) => handleFieldChange(index, 'firstname', e.target.value)}
            fullWidth
            margin="normal"
            style={{ flex: 1, marginRight: '8px' }}
            disabled={index === 0 || !editMode}
          />

          {/* Last Name */}
          <TextField
            label="Last Name"
            value={index === 0 ? formData.last_name : field.lastname}
            onChange={(e) => handleFieldChange(index, 'lastname', e.target.value)}
            fullWidth
            margin="normal"
            style={{ flex: 1, marginRight: '8px' }}
            disabled={index === 0 || !editMode}
          />

          {/* Gender with Autocomplete */}
          <Autocomplete
            freeSolo
            options={['Male', 'Female', ...suggestions.map((option) => option.gender)]}
            inputValue={index === 0 ? formData.gender ?? "" : field.gender ?? ""}
            onInputChange={(event, newInputValue) => {
              handleSearch(index, 'gender', newInputValue);
              handleFieldChange(index, 'gender', newInputValue);
            }}
            onChange={(event, newValue) => {
              const selectedOption = suggestions.find(option => option.gender === newValue);
              if (selectedOption) {
                handleSuggestionSelect(index, selectedOption);
              } else {
                handleFieldChange(index, 'gender', newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Gender"
                fullWidth
                margin="normal"
                style={{ flex: 2, marginRight: '8px' }}  // Make gender field wider
                disabled={index === 0 || !editMode}
              />
            )}
          />

          {/* Position in Family */}
          <TextField
            select
            label="Position in Family"
            name="position_in_family"
            value={index === 0 ? formData.position ?? "" : field.position ?? ""}
            onChange={(e) => handleFieldChange(index, 'position', e.target.value)}
            fullWidth
            margin="normal"
            style={{ flex: 1, marginRight: '8px' }}  // Make position field shorter
            disabled={!editMode}
          >
            {[...Array(10).keys()].map((pos) => {
              const positionLabel = `${pos + 1}${['st', 'nd', 'rd'][pos] || 'th'} Child`;
              return (
                <MenuItem key={pos} value={pos + 1}>
                  {positionLabel}
                </MenuItem>
              );
            })}
          </TextField>

          {/* Remove Button */}
          {index !== 0 && (
            <IconButton
              color="secondary"
              onClick={() => handleRemoveField(index)}
              style={{ marginLeft: '8px' }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      ))}

      {/* Add Family Member Button */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
        <Button onClick={handleAddField} variant="contained" color="secondary" disabled={!editMode}>
          Add Family Member
        </Button>
      </div>
    </div>
  );
};

export default AddFamilyMember;
