import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Box, Paper, Avatar, IconButton, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import API_BASE_URL from '../config';
import UserProfileDialog from './UserProfileDialog';

export default function Search() {
  const [first_name, setFirstName] = useState('');
  const [area, setArea] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [uid, setuid] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // State to store selected user profile
  const [openProfileDialog, setOpenProfileDialog] = useState(false); // State to control the dialog

  const isSearchDisabled = !first_name && !area && !district && !state && !uid;

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/search`, {
        params: {
          first_name,
          area,
          district,
          state,
          uid,
        },
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error('Search failed:', error);
    }
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 500), [
    first_name,
    area,
    district,
    state,
    uid,
  ]);

  useEffect(() => {
    if (first_name || area || district || state || uid) {
      debouncedSearch();
    }
  }, [first_name, area, district, state, uid, debouncedSearch]);

  const fetchUserProfile = async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${userId}`);
      setSelectedUser(response.data);
      setOpenProfileDialog(true);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  };

  const handleProfileClose = () => {
    setOpenProfileDialog(false);
    setSelectedUser(null);
  };

  return (
    <Paper sx={{ padding: 3, marginTop: 2 }}>
      <Box component="form" sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          label="Name"
          name="first_name"
          value={first_name}
          size="small"
          onChange={(e) => setFirstName(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="City/Town/Village"
          name="area"
          value={area}
          size="small"
          onChange={(e) => setArea(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="District"
          name="district"
          value={district}
          size="small"
          onChange={(e) => setDistrict(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="State"
          name="state"
          value={state}
          size="small"
          onChange={(e) => setState(e.target.value)}
          variant="outlined"
        />
        <TextField
          label="Unique ID"
          name="uid"
          value={uid}
          size="small"
          onChange={(e) => setuid(e.target.value)}
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleSearch} disabled={isSearchDisabled}>
          Search
        </Button>
      </Box>

      {/* Display search results */}
      <Box sx={{ marginTop: 4 }}>
        {searchResults.length > 0 ? (
          searchResults.map((result, index) => (
            <Paper key={index} sx={{ padding: 2, marginBottom: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {result.image_url ? (
                  <img
                    src={result.image_url}
                    alt={result.first_name}
                    style={{ width: '56px', height: '56px', borderRadius: '50%' }}
                  />
                ) : (
                  <Avatar sx={{ width: 56, height: 56 }}>{result.first_name[0]}</Avatar>
                )}
                <Box>
                  <Typography variant="h6">{result.first_name} {result.last_name}</Typography>
                  <Typography variant="body2">
                    <strong>Address:</strong> {result.area}, {result.district}, {result.state} - {result.pincode}
                  </Typography> <Typography variant="body2">
                    <strong>Uid:</strong> {result.uid}
                  </Typography>
                </Box>
                <IconButton color="primary" aria-label="view profile" onClick={() => fetchUserProfile(result.uid)}>
                  <VisibilityIcon />
                </IconButton>
              </Box>
            </Paper>
          ))
        ) : (
          <Typography>No results found.</Typography>
        )}
      </Box>

      {/* Profile Dialog */}
      <UserProfileDialog
        selectedUser={selectedUser}
        openProfileDialog={openProfileDialog}
        handleProfileClose={handleProfileClose}
      />

    </Paper>
  );
}
