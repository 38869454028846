import React, { useState } from 'react';
import { TextField, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const CustomFieldComponent = ({ editMode }) => {
    const [fields, setFields] = useState([
        { title: '', fieldName: '' },
    ]);

    // Add a new custom field
    const handleAddField = () => {
        setFields([...fields, { title: '', fieldName: '' }]);
    };

    // Update field values
    const handleFieldChange = (index, field, value) => {
        const updatedFields = [...fields];
        updatedFields[index][field] = value;
        setFields(updatedFields);
    };

    // Remove a custom field
    const handleRemoveField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    return (
        <div>
            {fields.map((field, index) => (
                <div key={index} className="field-entry">
                    <div className='row'>
                        <div className="col-lg-3">
                            <TextField
                                label="Field Title"
                                value={field.title}
                                onChange={(e) => handleFieldChange(index, 'title', e.target.value)}
                                fullWidth
                                margin="normal"
                                disabled={!editMode}
                            />
                        </div>

                        <div className="col-lg-6">
                            <TextField
                                label="Field Name"
                                value={field.fieldName}
                                onChange={(e) => handleFieldChange(index, 'fieldName', e.target.value)}
                                fullWidth
                                margin="normal"
                                disabled={!editMode}
                            />
                        </div>

                        <div className='col-lg-2'>
                            {index > 0 && (
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleRemoveField(index)}
                                    sx={{ mt: 2 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>
                        <div className='col-lg-2'>

                        </div>
                    </div>
                </div>
            ))}

            <div className="d-flex justify-content-end">
                <Button
                    onClick={handleAddField}
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 2 }}
                    disabled={!editMode}
                >
                    Add Custom Field
                </Button>
            </div>
        </div>
    );
};

export default CustomFieldComponent;
