import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TextField, Button, Box, Paper } from '@mui/material';
import axios from 'axios';
import ProfilePictureUpload from './ProfilePictureUpload';
import AddFamilyMember from './AddFamilyMember';
import AddJobDetail from './AddJobDetail';
import AddEducationDetail from './AddEducationDetail';
import Address from './Address';
import API_BASE_URL from '../config';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { Tooltip, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomFieldComponent from './CustomField';
import { Typography, Stack } from '@mui/material';
import ModernDivider from './ModernDivider';

const EditProfile = () => {
  // const loggedinUser = useSelector((state) => console.log(state));
  const [formData, setFormData] = useState({
    prefix: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    display_name: '',
    phone_number: '',
    email_id: '',
    address: '',
    area: '',
    district: '',
    state: '',
    pincode: '',
    dob: '',
    age: '',
    gender: '',
    uid: '',
  });

  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [editMode, setEditMode] = useState(false); // Add state for toggle

  useEffect(() => {
    //console.log('loggedinUser',loggedinUser);
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user/id`);
        const userId = response.data.userId;
        const userResponse = await axios.get(`${API_BASE_URL}/user/profile?id=${userId}`);
        const userData = userResponse.data;
        //console.log('formDAta', formData);
        setFormData({
          ...formData,
          ...userData,
        });
        setProfilePicUrl(userData.image_url || '/goldpetals/default-profile.png');
      } catch (error) {
        console.error('Failed to fetch user data', error);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDOBChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'dob') {
      const age = calculateAge(value);
      setFormData({ ...formData, dob: value, age });
    }
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const handleToggleEditMode = () => {
    setEditMode((prevEditMode) => !prevEditMode); // Toggle between edit and view mode
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // Append all form fields to the FormData object
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });

    // If the profile picture is available, append it to FormData
    if (profilePicUrl && profilePicUrl !== '/goldpetals/default-profile.png') {
      const profilePictureBlob = await fetch(profilePicUrl)
        .then(res => res.blob())
        .then(blob => blob);

      formDataToSend.set('image_url', profilePictureBlob, 'profile-pic.jpg');
    }
    // console.log('----------)))))))))))', formDataToSend);
    try {
      const response = await fetch(`${API_BASE_URL}/user/update-profile`, {
        method: 'PUT',
        headers: {
          // Do not set 'Content-Type' header as 'multipart/form-data'; the browser sets it automatically.
        },
        body: formDataToSend,
      });

      if (response.ok) {
        alert('Profile updated successfully!');
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Failed to update profile', error);
    }
  };


  const [tooltipText, setTooltipText] = useState('Copy Unique Id');

  const handleCopy = () => {
    navigator.clipboard.writeText(formData.uid)
      .then(() => {
        setTooltipText('Unique Id Copied!'); // Update tooltip text
        setTimeout(() => setTooltipText('Copy Unique Id'), 2000); // Reset tooltip after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };


  const handleProfilePicUpdate = (newProfilePicUrl) => {
    setProfilePicUrl(newProfilePicUrl);
    // setFormData({ ...formData, image_url: newProfilePicUrl });
  };
  const capitalize = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div maxwidth="sm">
      <Paper sx={{ padding: 2, marginTop: 2, position: 'relative' }}>
        <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: 2, boxShadow: 2 }}>
          {/* Welcome Message */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6" fontWeight="bold">
              Welcome:
            </Typography>
            <Typography variant="h6" color="secondary">
              {capitalize(formData.first_name)} {capitalize(formData.last_name)}
            </Typography>
          </Stack>

          {/* Unique ID */}
          <Stack direction="row" spacing={1} alignItems="center" sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Your Unique ID:
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {formData.uid}
            </Typography>
            <Tooltip title={tooltipText}>
              <div>
                <IconButton onClick={handleCopy} sx={{ color: 'secondary.main', cursor: 'pointer' }}>
                  <ContentCopyIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Stack>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleToggleEditMode}
          sx={{ marginBottom: 2, marginTop: 3, position: 'absolute', right: '22px', top: 0 }}
        >
          {editMode ? <Tooltip title="Disable Edit"><EditOffIcon /> </Tooltip> : <Tooltip title="Edit Profile"><EditIcon /></Tooltip>}
        </Button>

        <ModernDivider textAlign="left" sx={{ fontSize: 20, fontWeight: 'bold', backgroundColor: '#e7e7e7', margin: '40px 0px' }}>
          Personal Details
        </ModernDivider>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <ProfilePictureUpload editMode={editMode} onProfilePicUpdate={handleProfilePicUpdate} />
          <div className='row'>
            <div className='col-lg-3'>
              <TextField
                label="Prefix"
                name="prefix"
                value={formData.prefix || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={!editMode} // Disable if not in edit mode
              />
            </div>
            <div className='col-lg-3'>
              <TextField
                label="First Name"
                name="first_name"
                value={formData.first_name || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
              />
            </div>

            <div className='col-lg-3'>
              <TextField
                label="Middle Name"
                name="middle_name"
                value={formData.middle_name || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
              />
            </div>
            <div className='col-lg-3'>
              <TextField
                label="Last Name"
                name="last_name"
                value={formData.last_name || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3'>
              <TextField
                label="Display Name/ Pet Name"
                name="display_name"
                value={formData.display_name || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
              />
            </div>
            <div className='col-lg-3'>
              <TextField
                label="Phone Number"
                name="phone_number"
                value={formData.phone_number || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                inputProps={{
                  maxLength: 10, // Limit the input to 10 characters
                  inputMode: 'numeric', // Ensures the numeric keyboard on mobile devices
                }}
                disabled={!editMode}
              />
            </div>
            <div className='col-lg-3'>
              <TextField
                label="Email ID"
                name="email_id"
                value={formData.email_id || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
              />
            </div>


            <div className='col-lg-3'>
              <TextField
                label="Gender"
                name="gender"
                value={formData.gender || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={!editMode}
              />
            </div>

          </div>

          <div className='row'>
            <div className='col-lg-3'>
              <TextField
                label="Date of Birth"
                name="dob"
                type="date"
                value={formData.dob || ''}
                onChange={handleDOBChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!editMode}
              />
            </div>

            <div className='col-lg-3'>
              <TextField
                label="Age"
                name="age"
                value={formData.age || ''}
                fullWidth
                margin="normal"
                disabled
              />
            </div>
          </div>
          <ModernDivider text='Address'>
            Address
          </ModernDivider>
          <Address
            formData={formData}
            setFormData={setFormData} // Pass state update function to child
            editMode={true} // You can control edit mode based on your needs
          />
          <ModernDivider text='Family Details'>
            Family Details
          </ModernDivider>
          <AddFamilyMember formData={formData} editMode={editMode} />
          <ModernDivider text='Education Details'>
            Education Details
          </ModernDivider>
          <AddEducationDetail editMode={editMode} />
          <ModernDivider text='Occupation Details'>
            Occupation Details
          </ModernDivider>
          <AddJobDetail editMode={editMode} />
          <ModernDivider text='Custom Fields'>
            Custom Fields
          </ModernDivider>
          <CustomFieldComponent editMode={editMode} />
          {/* <Button variant="contained" color="primary" onClick={handleCapture}>
        Save Website as Image
      </Button> */}
          {editMode && (
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, ml: 2 }}>
              Save Changes
            </Button>
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default EditProfile;
