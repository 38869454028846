import React, { useState } from 'react';
import { TextField, IconButton, Grid, Button, Autocomplete, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import API_BASE_URL from '../config';

const AddressForm = ({ formData, editMode }) => {
    const [addresses, setAddresses] = useState([
        {
            id: 1,
            type: formData.type || '',
            area: formData.area || '',
            city: formData.city || '',
            district: formData.district || '',
            state: formData.state || '',
            pincode: formData.pincode || '',
        },
    ]);

    const [suggestions, setSuggestions] = useState([]);

    // Address types that will act as suggestions
    const addressTypeSuggestions = [
        'Current Address', 'Permanent Address', 'Office Address', 'Holiday Home Address'
    ];

    const handleAddAddress = () => {
        setAddresses([
            ...addresses,
            {
                id: addresses.length + 1,
                type: '',
                area: '',
                city: '',
                district: '',
                state: '',
                pincode: '',
            }
        ]);
    };

    const handleRemoveAddress = (index) => {
        const newAddresses = [...addresses];
        newAddresses.splice(index, 1);
        setAddresses(newAddresses);
    };

    const handleFieldChange = (index, field, value) => {
        const newAddresses = [...addresses];
        newAddresses[index][field] = value;
        setAddresses(newAddresses);
    };

    const handleSearch = async (index, field, value) => {
        if (value) {
            try {
                const response = await axios.get(`${API_BASE_URL}/search`, {
                    params: {
                        [field]: value
                    }
                });

                if (response.data.length > 0) {
                    setSuggestions(response.data);
                } else {
                    setSuggestions([]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setSuggestions([]);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionSelect = (index, option) => {
        const newAddresses = [...addresses];
        newAddresses[index].address = option.address;
        setAddresses(newAddresses);
        setSuggestions([]);  // Clear suggestions after selection
    };

    return (
        <div>
            {addresses.map((address, index) => (
                <div key={index} className='address-container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <Autocomplete
                                freeSolo
                                options={addressTypeSuggestions}
                                value={address.type}
                                onInputChange={(event, newInputValue) => {
                                    handleFieldChange(index, 'type', newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        handleFieldChange(index, 'type', newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Address Type"
                                        fullWidth
                                        margin="normal"
                                        disabled={!editMode}
                                    />
                                )}
                            />
                        </div>

                        <div className='col-lg-3'>
                            <Autocomplete
                                freeSolo
                                options={suggestions.map((option) => option.address)}
                                inputValue={index === 0 ? formData.address : address.address}
                                onInputChange={(event, newInputValue) => {
                                    handleSearch(index, 'address', newInputValue);
                                    handleFieldChange(index, 'address', newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                    const selectedOption = suggestions.find(option => option.address === newValue);
                                    if (selectedOption) {
                                        handleSuggestionSelect(index, selectedOption);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="area No./ Plot No./ Sector"
                                        fullWidth
                                        margin="normal"
                                        disabled={index === 0 ? index === 0 : !editMode}
                                    />
                                )}
                            />
                        </div>

                        <div className='col-lg-3'>
                            <Autocomplete
                                freeSolo
                                options={suggestions.map((option) => option.area)}
                                inputValue={index === 0 ? formData.area : address.area}
                                onInputChange={(event, newInputValue) => {
                                    handleSearch(index, 'area', newInputValue);
                                    handleFieldChange(index, 'area', newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                    const selectedOption = suggestions.find(option => option.area === newValue);
                                    if (selectedOption) {
                                        handleSuggestionSelect(index, selectedOption);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Village/ City/ Town"
                                        fullWidth
                                        margin="normal"
                                        disabled={index === 0 ? index === 0 : !editMode}
                                    />
                                )}
                            />
                        </div>

                        <div className='col-lg-3'>
                            <Autocomplete
                                freeSolo
                                options={suggestions.map((option) => option.district)}
                                inputValue={index === 0 ? formData.district : address.district}
                                onInputChange={(event, newInputValue) => {
                                    handleSearch(index, 'district', newInputValue);
                                    handleFieldChange(index, 'district', newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                    const selectedOption = suggestions.find(option => option.district === newValue);
                                    if (selectedOption) {
                                        handleSuggestionSelect(index, selectedOption);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="District"
                                        fullWidth
                                        margin="normal"
                                        disabled={index === 0 ? index === 0 : !editMode}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <Autocomplete
                                freeSolo
                                options={suggestions.map((option) => option.state)}
                                inputValue={index === 0 ? formData.state : address.state}
                                onInputChange={(event, newInputValue) => {
                                    handleSearch(index, 'state', newInputValue);
                                    handleFieldChange(index, 'state', newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                    const selectedOption = suggestions.find(option => option.state === newValue);
                                    if (selectedOption) {
                                        handleSuggestionSelect(index, selectedOption);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="State"
                                        fullWidth
                                        margin="normal"
                                        disabled={index === 0 ? index === 0 : !editMode}
                                    />
                                )}
                            />
                        </div>

                        <div className='col-lg-3'>
                            <Autocomplete
                                freeSolo
                                options={suggestions.map((option) => String(option.pincode))} // Convert options to strings
                                inputValue={index === 0 ? String(formData.pincode) : String(address.pincode)} // Convert inputValue to string
                                onInputChange={(event, newInputValue) => {
                                    const inputString = String(newInputValue || ''); // Convert to string
                                    handleSearch(index, 'pincode', inputString);
                                    handleFieldChange(index, 'pincode', inputString);
                                }}
                                onChange={(event, newValue) => {
                                    const selectedValue = String(newValue || ''); // Convert to string
                                    const selectedOption = suggestions.find(option => String(option.pincode) === selectedValue);
                                    if (selectedOption) {
                                        handleSuggestionSelect(index, selectedOption);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pin Code"
                                        fullWidth
                                        margin="normal"
                                        disabled={index === 0 ? index === 0 : !editMode}
                                    />
                                )}
                            />

                        </div>

                        <div className='col-lg-3'>
                            {index > 0 && (
                                <IconButton
                                    color="secondary"
                                    onClick={() => handleRemoveAddress(index)}
                                    sx={{ mt: 2 }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>
                </div>
            ))}

            <div className="d-flex justify-content-end">
                <Button variant="contained" color="secondary" onClick={handleAddAddress} disabled={!editMode}>
                    Add Address
                </Button>
            </div>
        </div>
    );
};

export default AddressForm;
