import React from 'react';

const ModernDivider = ({ text }) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      margin: '20px 0',
      position: 'relative',
      fontWeight: 'bold',
      fontSize: '20px',
    }}>
      {/* Left part of the line */}
      <div style={{
        flexGrow: 1,
        height: '1px',
        backgroundColor: '#e7e7e7',
      }}></div>
      
      {/* Text in the middle */}
      <span style={{
        padding: '0 15px',
        backgroundColor: '#ffffff',  // Background to overlay the line
        color: '#333',  // Adjust text color
      }}>
        {text}
      </span>

      {/* Right part of the line */}
      <div style={{
        flexGrow: 1,
        height: '1px',
        backgroundColor: '#e7e7e7',
      }}></div>
    </div>
  );
};

export default ModernDivider;
