import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Box, Menu, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import API_BASE_URL from '../config'; // Replace with your API base URL
import axios from 'axios';
import { Masonry } from "@mui/lab";

const Photos = ({ photos, fetchPhotos }) => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected photo index
  const [anchorEl, setAnchorEl] = useState(null); // For handling options menu
  const openMenu = Boolean(anchorEl); // Whether the options menu is open

  // Handle opening the modal to view a photo
  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  // Handle closing the modal
  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(null);
  };

  // Handle navigating to the next photo
  const handleNext = () => {
    if (selectedIndex < photos.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  // Handle navigating to the previous photo
  const handlePrevious = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  // Open the options menu (delete, make private/public)
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the options menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle deleting a photo
  const handleDelete = async () => {
    const photoId = photos[selectedIndex]?.id;
    try {
      await axios.delete(`${API_BASE_URL}/photos/${photoId}`);
      fetchPhotos(); // Refetch the photos after deletion
      handleClose(); // Close the dialog after deletion
      handleMenuClose(); // Close the menu after deletion
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  // Handle toggling photo status (public/private)
  const handleToggleVisibility = async () => {
    const photoId = photos[selectedIndex]?.id;
    const newStatus = photos[selectedIndex].status === 1 ? 0 : 1; // Toggle status

    try {
      await axios.patch(`${API_BASE_URL}/photos/${photoId}`, { status: newStatus });
      fetchPhotos(); // Refetch the photos after status change
      handleMenuClose(); // Close the options menu
    } catch (error) {
      console.error('Error updating visibility:', error);
    }
  };

  return (
    <div>
      <Masonry spacing={2}>
        {photos.length > 0 ? (
          photos.map((photo, index) => (
            <div key={photo.id} onClick={() => handleOpen(index)} style={{ cursor: "pointer" }}>
              <img
                src={`https://family.seatechdigital.com/goldpetals/uploads/${photo.filename}`}
                alt={photo.altText || "Photo"}
                height={250}
                style={{ width: "100%", objectFit: "cover" }} // Make image responsive
              />
              {/* Show status of the photo */}
              <p style={{ textAlign: 'center', marginTop: '8px', fontWeight: 'bold' }}>
                {photo.status === 1 ? "Public" : "Private"}
              </p>
            </div>
          ))
        ) : (
          <p>No photos available</p>
        )}
      </Masonry>

      {/* Photo Viewer Dialog */}
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="lg" 
        fullWidth
        sx={{
          '.MuiPaper-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)', // Darker translucent background
            backdropFilter: 'blur(10px)', // Apply blur to the background
            boxShadow: 'none', // Remove shadow to emphasize the blur
          }
        }}
      >
        <DialogTitle sx={{ color: 'white' }}>
          {photos[selectedIndex]?.altText || "Photo"}
          
          {/* Close Button */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: "absolute", right: 10, top: 10, color: "white" }}
          >
            <CloseIcon />
          </IconButton>

          {/* Options button (Delete, Make Private/Public) */}
          <IconButton
            aria-label="options"
            onClick={handleMenuClick}
            style={{ position: "absolute", right: 50, top: 10, color: "white" }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleDelete}>Delete Photo</MenuItem>
            <MenuItem onClick={handleToggleVisibility}>
              {photos[selectedIndex]?.status === 1 ? "Make Private" : "Make Public"}
            </MenuItem>
          </Menu>
        </DialogTitle>

        <DialogContent dividers sx={{ color: 'white' }}>
          {photos[selectedIndex] && (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh', // Make photo viewer take up 80% of the viewport height
                overflow: 'hidden', // Prevent scrolling
              }}
            >
              {/* Previous Button */}
              <IconButton
                onClick={handlePrevious}
                disabled={selectedIndex === 0} // Disable if it's the first photo
                sx={{ position: 'absolute', left: 10, zIndex: 1, color: "white" }}
              >
                <ArrowBackIosIcon />
              </IconButton>

              {/* Photo */}
              <img
                src={`https://family.seatechdigital.com/goldpetals/uploads/${photos[selectedIndex]?.filename}`}
                alt={photos[selectedIndex]?.altText || "Photo"}
                style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }} // Display photo with full height, contain within viewport
              />

              {/* Next Button */}
              <IconButton
                onClick={handleNext}
                disabled={selectedIndex === photos.length - 1} // Disable if it's the last photo
                sx={{ position: 'absolute', right: 10, zIndex: 1, color: "white" }}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Photos;
