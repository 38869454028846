// src/config.js



// helpers.js
import { useCallback, useState } from "react";
 const API_BASE_URL = 'https://family.seatechdigital.com:5001/goldpetals/api';

 // const API_BASE_URL = 'http://localhost:5001/goldpetals/api';

  const BASE_URL = 'https://family.seatechdigital.com/goldpetals/';

 export default API_BASE_URL; // Default export
 export { BASE_URL }; // Named export



export const sendOtp = async (input) => {
  try {
    const response = await fetch(`${API_BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ input }),
    });

    if (!response.ok) {
      throw new Error('Error sending OTP');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error sending OTP:', error);
    throw error;
  }
};

export const updateOtp = async (input, otp) => {
  try {
    const response = await fetch(`${API_BASE_URL}/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ input, otp }),
    });

    if (!response.ok) {
      throw new Error('Error updating OTP');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating OTP:', error);
    throw error;
  }
};

export const verifyOtp = async (email_id, phone_number, otp) => {
  try {
    const response = await fetch(`${API_BASE_URL}/verify-otp`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email_id, phone_number, otp }),
    });

    if (!response.ok) {
      throw new Error('Error verifying OTP');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error verifying OTP:', error);
    throw error;
  }
};

export const uidLogin = async (uid, password) => {
  try {
    const response = await fetch(`${API_BASE_URL}/uid-login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uid, password }),
    });

    if (!response.ok) {
      throw new Error('Error logging in with UID');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error logging in with UID:', error);
    throw error;
  }
};







export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 5 });
    }
  }, []);
  return [translate, containerRef];
};
