import React, { useState } from 'react';
import { TextField, Button, MenuItem, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const AddEducationDetail = ({ editMode }) => {
    const [educationDetails, setEducationDetails] = useState([
        { level: '', startYear: '', endYear: '', institute: '', isPresent: false }
    ]);

    const handleEducationChange = (index, field, value) => {
        const newEducationDetails = [...educationDetails];
        newEducationDetails[index][field] = value;
        setEducationDetails(newEducationDetails);
    };

    const addEducationDetail = () => {
        setEducationDetails([
            ...educationDetails,
            { level: '', startYear: '', endYear: '', institute: '', isPresent: false }
        ]);
    };

    const handleRemoveEducationDetail = (index) => {
        const newEducationDetails = educationDetails.filter((_, i) => i !== index);
        setEducationDetails(newEducationDetails);
    };

    const handleCheckboxChange = (index, e) => {
        handleEducationChange(index, 'isPresent', e.target.checked);
    };

    return (
        <div>
            {educationDetails.map((education, index) => (
                <div key={index} className="education-detail-row" style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                    {/* Education Level */}
                    <TextField
                        select
                        label="Education Level"
                        value={education.level}
                        onChange={(e) => handleEducationChange(index, 'level', e.target.value)}
                        fullWidth
                        margin="normal"
                        style={{ flex: 1, marginRight: '10px' }}
                        disabled={!editMode}
                    >
                        <MenuItem value="">Select Level</MenuItem>
                        <MenuItem value="SSC">SSC</MenuItem>
                        <MenuItem value="HSC">HSC</MenuItem>
                        <MenuItem value="Graduation">Graduation</MenuItem>
                        <MenuItem value="PostGraduation">PostGraduation</MenuItem>
                        <MenuItem value="PhD">PhD</MenuItem>
                    </TextField>

                    {/* School/ College/ Institute Name */}
                    <TextField
                        label="School/ College/ Institute Name"
                        value={education.institute}
                        onChange={(e) => handleEducationChange(index, 'institute', e.target.value)}
                        fullWidth
                        margin="normal"
                        style={{ flex: 2, marginRight: '10px' }}
                        disabled={!editMode}
                    />

                    {/* Start Year */}
                    <TextField
                        label="Start Year"
                        type="number"
                        value={education.startYear}
                        onChange={(e) => handleEducationChange(index, 'startYear', e.target.value)}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: 1900, max: new Date().getFullYear() }}
                        style={{ flex: 1, marginRight: '10px' }}
                        disabled={!editMode}
                    />

                    {/* End Year (if not present) */}
                    {!education.isPresent && (
                        <TextField
                            label="End Year"
                            type="number"
                            value={education.endYear}
                            onChange={(e) => handleEducationChange(index, 'endYear', e.target.value)}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ min: 1900, max: new Date().getFullYear() }}
                            style={{ flex: 1, marginRight: '10px' }}
                            disabled={!editMode}
                        />
                    )}

                    {/* Present Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={education.isPresent}
                                onChange={(e) => handleCheckboxChange(index, e)}
                                name="isPresent"
                                color="primary"
                                disabled={!editMode}
                            />
                        }
                        label="Present"
                        style={{ marginRight: '10px' }}
                    />

                    {/* Delete Button */}
                    {index > 0 && (
                        <IconButton
                            color="secondary"
                            onClick={() => handleRemoveEducationDetail(index)}
                            sx={{ mt: 2 }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            ))}

            {/* Add New Education Button */}
            <div className="d-flex justify-content-end" style={{ marginTop: '16px' }}>
                <Button variant="contained" color="secondary" onClick={addEducationDetail} sx={{ mt: 2 }} disabled={!editMode}>
                    Add Education Detail
                </Button>
            </div>
        </div>
    );
};

export default AddEducationDetail;
