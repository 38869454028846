import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeButton from './HomeButton'; // Adjust the import path as needed
import {  Diversity1, People, Diversity2,  Photo } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import SearchButton from './SearchButton';
import GalleryButton from './GalleryButton';
import SettingButton from './SettingButton';
import MyFamilyTreeButton from './MyFamilyTreeButton';
import FindRelationButton from './FindRelationButton';
import FindPartnerButton from './FindPartnerButton';
export const mainListItems = (
  <React.Fragment>
    <HomeButton/>
     <MyFamilyTreeButton/>
   <SearchButton/>
   <FindRelationButton/>
   <FindPartnerButton/>
  
   

    <GalleryButton/>

    {/* <ListItemButton>
      <ListItemIcon>
        <InfoIcon />
      </ListItemIcon>
      <ListItemText primary="AboutUs" />
    </ListItemButton> */}
    <SettingButton/>
  </React.Fragment>
);

