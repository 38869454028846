import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, IconButton, Grid } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CancelIcon from '@mui/icons-material/Cancel';
import Photos from './Photos';
import API_BASE_URL from '../config'; // Replace with your API base URL

const GalleryAdd = ({ userId }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [id, setId] = useState();

  // Define the fetchPhotos function in the parent component
  const fetchPhotos = async (userId) => {
    try {
      // Pass userId as a query parameter to the API
      const response = await axios.get(`${API_BASE_URL}/photos`, {
        params: { userId }, // Sending userId as a query parameter
      });

      console.log('Fetched photos:', response.data);
      setPhotos(response.data); // Update the state with the fetched photos

    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      try {
        let currentUserId = userId;
        if (!userId) {
          const userResponse = await axios.get(`${API_BASE_URL}/user/id`);
          currentUserId = userResponse.data.userId;
          setId(currentUserId);
          console.log('Fetched userId:', currentUserId);
        }

        // Fetch photos for the user
        fetchPhotos(currentUserId); // Fetch photos once the user ID is available
      } catch (error) {
        console.error("Error initializing user:", error);
      }
    };

    initializeUser();
  }, [userId]);

  // Create image previews when files are selected
  useEffect(() => {
    const newPreviews = Array.from(selectedFiles).map(file =>
      URL.createObjectURL(file)
    );
    setPreviews(newPreviews);

    // Cleanup function to revoke object URLs
    return () => {
      previews.forEach(preview => URL.revokeObjectURL(preview));
    };
  }, [selectedFiles]);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    for (const file of selectedFiles) {
      formData.append('photos', file);
      formData.append('userId', id);
    }

    try {
      // Upload photos
      await axios.post(`${API_BASE_URL}/photos`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Fetch and display the updated photos after successful upload
      fetchPhotos(id); // Call fetchPhotos to update the photos state

      // Clear selected files and previews after upload
      setSelectedFiles([]);
      setPreviews([]);
    } catch (error) {
      console.error('Error uploading photos:', error);
    }
  };

  const removeFile = (index) => {
    const newFiles = Array.from(selectedFiles);
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="file-upload"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="file-upload">
        <IconButton color="primary" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={selectedFiles.length === 0}
      >
        Upload Photos
      </Button>
      
      {/* Show previews of selected images */}
      <div style={{ marginTop: 20 }}>
        {previews.length > 0 && (
          <Grid container spacing={2}>
            {previews.map((preview, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ position: 'relative' }}>
                <img
                  src={preview}
                  alt={`preview-${index}`}
                  style={{ width: '100%', height: 'auto', borderRadius: 4 }}
                />
                <IconButton
                  onClick={() => removeFile(index)}
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: '0',
                    backgroundColor: 'transparent',
                    borderRadius: '50%',
                    zIndex: 1,
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        )}
      </div>

      {/* Pass photos and fetchPhotos function to the Photos component */}
      <Photos photos={photos} fetchPhotos={() => fetchPhotos(id)} />
    </div>
  );
};

export default GalleryAdd;
