import React, { useState, useEffect, useCallback } from 'react';
import { Box, Avatar, Button, Modal, Typography, Slider, IconButton } from '@mui/material';
import Cropper from 'react-easy-crop';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import API_BASE_URL from '../config';

const ProfilePictureUpload = ({ editMode, onProfilePicUpdate }) => {
  const [profilePicUrl, setProfilePicUrl] = useState('default-profile.png'); // Default image
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null);

  // Fetch existing profile picture from the database
  useEffect(() => {
    const fetchProfilePic = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/user/id`);
        const userId = response.data.userId;
        const responseUser = await axios.get(`${API_BASE_URL}/users/${userId}/profile-pic`);
        if (responseUser.data && responseUser.data.profilePicUrl) {
          setProfilePicUrl(`${responseUser.data.profilePicUrl}`);
        }
      } catch (err) {
        console.error('Error fetching profile picture:', err);
      }
    };

    fetchProfilePic();
  }, [userId]);

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const imageUrl = URL.createObjectURL(file);
      setImageSrc(imageUrl);
      setIsCropModalOpen(true);
      setError('');
    } else {
      setError('Please select a valid image file.');
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    setProfilePicUrl(croppedImage);
    setIsCropModalOpen(false);
    onProfilePicUpdate(croppedImage);
  };

  const getCroppedImg = (imageSrc, croppedAreaPixels) => {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );
    
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        resolve(url);
      }, 'image/jpeg');
    });
  };

  return (
    <Box>
      {/* Profile Picture Preview */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {profilePicUrl ? (
          // If the image exists, render an img tag inside a circle
          <img
            src={profilePicUrl ? `https://family.seatechdigital.com/goldpetals/${profilePicUrl}` : 'https://family.seatechdigital.com/goldpetals/default-profile.png'}
            alt="Profile"
            style={{
              width: 100,
              height: 100,
              borderRadius: '50%', // Ensure the image is circular
              border: '2px solid #1976D2',
              objectFit: 'cover',
            }}
          />
        ) : (
          // If no image exists, render an Avatar component
          <Avatar
            src={profilePicUrl ? `https://family.seatechdigital.com/goldpetals/${profilePicUrl}`  : 'https://family.seatechdigital.com/goldpetals/default-profile.png'}
            sx={{ width: 100, height: 100, mb: 2, border: '2px solid #1976D2' }}
          />
        )}

        {/* Upload Button beside Avatar */}
        <Button
          variant="contained"
          component="label"
          color="primary"
          disabled={!editMode}
          sx={{
            ml: 2, 
            borderRadius: '50%', 
            width: 50, 
            height: 50, 
            minWidth: 'auto', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            boxShadow: 3,
            mx:-2,
          }}
        >
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleProfilePicChange}
          />
          <Typography variant="button" sx={{ color: 'white' }}>
            +
          </Typography>
        </Button>
      </Box>

      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}

      {/* Crop Modal */}
      <Modal
        open={isCropModalOpen}
        onClose={() => setIsCropModalOpen(false)}
      >
        <Box sx={{ position: 'relative', width: '100%', maxWidth: 600, bgcolor: 'white', p: 4, mx: 'auto', borderRadius: 2, mt: 5 }}>
          <IconButton
            aria-label="close"
            onClick={() => setIsCropModalOpen(false)}
            sx={{ position: 'absolute', top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" sx={{ textAlign: 'center', mb: 3 }}>
            Adjust and Crop Image
          </Typography>

          <Box sx={{ position: 'relative', width: '100%', height: 300, mb: 3 }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              style={{ containerStyle: { borderRadius: '8px' } }}
            />
          </Box>

          <Typography variant="body2" sx={{ textAlign: 'center', mb: 2, color: 'gray' }}>
            Use the slider below to zoom in or out for better cropping.
          </Typography>

          <Box sx={{ width: '100%', mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              Zoom:
            </Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
              sx={{ maxWidth: 300 }}
            />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {Math.round(zoom * 100)}%
            </Typography>
          </Box>

          <Button
            onClick={handleCrop}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ py: 1.5 }}
          >
            Crop and Save
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProfilePictureUpload;
