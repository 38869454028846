// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice'; // Adjust the import path as necessary

// Create the store using configureStore
export const store = configureStore({
  reducer: {
    auth: authReducer, // Combine the auth reducer
    // Add other slices here if needed
  },
});
